import permitVersionService from "@/views/permitVersion/permitVersionService";
import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiService, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { IPermitVersion, IPermitVersionNew } from "@/views/permitVersion/permitVersion";
import type { AxiosResponse } from "axios";

const URL = "permit-versions";
const ENTITY_NAME = "Permit Version";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<IPermitVersion, IPaging<IPermitVersion>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getPermitVersions = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL;
  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getPermitVersionById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewPermitVersion = async () => {
  //optionally make any other changes as necessary before returning.
  return { data: permitVersionService.getInitialData() } as AxiosResponse<IPermitVersionNew, any>;
};

const createPermitVersion = (permitVersion: IPermitVersion, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, permitVersion, {
    successNotifCb,
    errorNotifCb,
  });
};

const updatePermitVersion = (permitVersion: IPermitVersion, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + permitVersion.id, permitVersion, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {PermitVersion} permitVersion This cannot be a 'ref' to a permitVersion
 */
const savePermitVersion = (permitVersion: IPermitVersion, config: IPagingApiAndNotifierConfig = {}) => {
  if (permitVersion.id) {
    return updatePermitVersion(permitVersion, config);
  } else {
    return createPermitVersion(permitVersion, config);
  }
};

/**
 * @param {Int} id
 */
const deletePermitVersionById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const amendPermitVersion = (amendPermitVersion: IPermitVersion, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL + "/amend", amendPermitVersion, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deletePermitVersionById,
  getPermitVersions,
  getPermitVersionById,
  getNewPermitVersion,
  createPermitVersion,
  updatePermitVersion,
  savePermitVersion,
  amendPermitVersion,
};
