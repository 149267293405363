import complaintService from "@/views/complaint/complaintService";
import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiService, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { IComplaint, IComplaintNew } from "@/views/complaint/complaint";
import type { AxiosResponse } from "axios";

const URL = "complaints";
const ENTITY_NAME = "Complaint";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<IComplaint, IPaging<IComplaint>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getComplaints = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO: TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getComplaintById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewComplaint = async () => {
  //optionally make any other changes as necessary before returning.
  return { data: complaintService.getInitialData() } as AxiosResponse<IComplaintNew, any>;
};

const createComplaint = (complaint: IComplaint, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, complaint, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateComplaint = (complaint: IComplaint, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + complaint.id, complaint, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Complaint} complaint This cannot be a 'ref' to a complaint
 */
const saveComplaint = (complaint: IComplaint, config: IPagingApiAndNotifierConfig = {}) => {
  if (complaint.id) {
    return updateComplaint(complaint, config);
  } else {
    return createComplaint(complaint, config);
  }
};

/**
 * @param {Int} id
 */
const deleteComplaintById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteComplaintById,
  getComplaints,
  getComplaintById,
  getNewComplaint,
  createComplaint,
  updateComplaint,
  saveComplaint,
};
