import {
  addressFromFormkit,
  addressToFormkit,
  plainAddressFromFormkit,
  plainAddressToFormkit,
} from "@modernary/mui/components/fk";

import { IFacilitySite } from "@/views/facilitySite/facilitySite";

export default {
  addAuthRequestFields,
  getInitialData,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: IFacilitySite) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}

function getInitialData() {
  const result = {} as IFacilitySite; //TODO:TS
  return addAuthRequestFields(result);
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit(data: IFacilitySite) {
  addressToFormkit(data, "mail");
  plainAddressToFormkit(data);
}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: any) {
  //TODO:TS
  addressFromFormkit(data as IFacilitySite, "mail");
  plainAddressFromFormkit(data as IFacilitySite);
}
