import { IInspection, IInspectionBE } from "@/views/inspection/inspection";
import { ref } from "vue";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  // fromFormkit,
  // toFormkit,
};

function addAuthRequestFields(entity: IInspection) {
  entity._authRequestFields = ["id", "facilitySiteId", "status", "inspector"];
  return entity;
}
function getInitialData() {
  const result = {
    type: null,
    inspectionDate: null,
    inspectionTime: null,
    contactPerson: "",
    contactPhone: "",
    contactEmail: "",
    comment: "",
    checklist: ref([]),
  } as unknown as IInspection; //TODO:TS
  return addAuthRequestFields(result);
}
function responseAdapter(response: { data: IInspection }) {
  addAuthRequestFields(response.data);
  return response.data;
}
function requestAdapter(data: IInspection) {
  // data is assumed to be from FormKit

  // Add seconds to the time so that the BE will parse it correctly.
  if (data.inspectionTime?.length === 5) {
    data.inspectionTime += ":00";
  }

  if (!data.inspectionDate) {
    data.inspectionDate = null;
  }

  if (!data.inspectionTime) {
    data.inspectionTime = null;
  }

  //switching to BE interface because facilitySite is required on IInspection,
  //but should not be sent back with request.
  const result = data as IInspectionBE;
  delete result.facilitySite;
  return result;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
// function toFormkit(data) {
// }

// // Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
// function fromFormkit(data) {
// }
