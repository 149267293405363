/* Not exported with the library. You'll want something similar to this for uniquely identifying external hosts in your App */
const SMART = Symbol("SMART");
const IDADMIN = Symbol("IDADMIN");
const DOCGEN = Symbol("DOCGEN");

export const apiHosts = {
  SMART,
  IDADMIN,
  DOCGEN,
};
