import { RouterView } from "vue-router";
import EntityAudits from "@/components/EntityAudits.vue";
import { EntityNotes } from "@modernary/mui/components/note";
// Troubleshoot your routes using this - https://paths.esm.dev

const names = {
  disposalReports: "DisposalReports",
  disposalReportList: "DisposalReportList",
  disposalReportNew: "DisposalReportNew",
  disposalReportNewSchedule: "DisposalReportNewSchedule",
  disposalReportNewSelect: "DisposalReportNewSelect",
  disposalReportBase: "DisposalReportBase",
  disposalReportView: "DisposalReportView",
  disposalReportEdit: "DisposalReportEdit",
  disposalReportNotes: "DisposalReportNotes",
  disposalReportNoteEdit: "DisposalReportNoteEdit",
  disposalReportNoteNew: "DisposalReportNoteNew",
  disposalReportAudit: "DisposalReportAudit",
};

export { names as disposalReportRouteNames };

/** @type {import('vue-router').RouterOptions['routes']} */
const disposalReportRoutes = [
  {
    path: "/disposalReports",
    name: names.disposalReports,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.disposalReportList },
    children: [
      {
        path: "list",
        name: names.disposalReportList,
        meta: { title: "Disposal Reports" },
        component: () => import("@/views/disposalReport/DisposalReportList.vue"),
      },
      {
        path: "new/:permitId(\\d+)?",
        component: () => import("@/views/disposalReport/DisposalReportBase.vue"),
        redirect: { name: names.disposalReportNew },
        props: true,
        children: [
          {
            path: ":reportScheduleId(\\d+)?",
            name: names.disposalReportNew,
            meta: { title: "Create Disposal Report", isNew: true },
            component: () => import("@/views/disposalReport/DisposalReportEdit.vue"),
          },
          {
            path: "schedule/:previousReportId(\\d+)?/:reportScheduleId(\\d+)?",
            name: names.disposalReportNewSchedule,
            meta: { title: "Schedule Next Disposal Report", isSchedule: true, isForm: true },
            component: () => import("@/views/disposalReport/DisposalReportEdit.vue"),
          },
          {
            path: "select",
            name: names.disposalReportNewSelect,
            meta: { title: "New Disposal Report", isNew: true },
            component: () => import("@/views/disposalReport/DisposalReportSelect.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.disposalReportBase,
        component: () => import("@/views/disposalReport/DisposalReportBase.vue"),
        redirect: { name: names.disposalReportView },
        children: [
          {
            path: "view",
            name: names.disposalReportView,
            meta: { title: "Disposal Report View" },
            component: () => import("@/views/disposalReport/DisposalReportView.vue"),
          },
          {
            path: "edit", // Also accepts a query param - start = true
            name: names.disposalReportEdit,
            meta: { title: "Disposal Report Edit", isForm: true },
            component: () => import("@/views/disposalReport/DisposalReportEdit.vue"),
          },
          {
            path: "notes",
            name: names.disposalReportNotes,
            meta: { title: "Disposal Report Internal Notes" },
            component: {
              render: () => <EntityNotes idField="reportId" routeNamePrefix="DisposalReportNote" />,
            },
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.disposalReportNoteEdit,
            meta: { title: "Edit Disposal Report Internal Note", isForm: true },
            component: () => import("@/views/disposalReport/DisposalReportNote.vue"),
          },
          {
            path: "notes/new",
            name: names.disposalReportNoteNew,
            meta: { title: "Create Disposal Report Internal Note", isForm: true },
            component: () => import("@/views/disposalReport/DisposalReportNote.vue"),
          },
          {
            path: "activity",
            name: names.disposalReportAudit,
            meta: { title: "Disposal Report Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="DisposalReport" />,
            },
          },
        ],
      },
    ],
  },
];
export default disposalReportRoutes;
