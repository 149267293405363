import { IComplaint } from "@/views/complaint/complaint";
import { addressFromFormkit, addressToFormkit } from "@modernary/mui/components/fk";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: IComplaint) {
  entity._authRequestFields = ["id"];
  return entity;
}
function getInitialData() {
  const result = {} as IComplaint; //TODO: ts
  return addAuthRequestFields(result);
}
function responseAdapter(response: { data: IComplaint }) {
  addAuthRequestFields(response.data);
  if (response.data.investigation) {
    response.data.investigations = JSON.parse(response.data.investigation);
  } else {
    response.data.investigations = [];
  }
  //TODO: TS
  return response.data;
}
function requestAdapter(data: IComplaint) {
  // Add seconds to the time so that the BE will parse it correctly.
  if (data.receivedTime?.length === 5) {
    data.receivedTime += ":00";
  }

  if (!data.receivedDate) {
    data.receivedDate = null;
  }

  if (!data.receivedTime) {
    data.receivedTime = null;
  }

  if (data.investigations) {
    data.investigation = JSON.stringify(data.investigations);
  }
  return data;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit(data: IComplaint) {
  addressToFormkit(data, "complainantMail");
  //TODO: Mask Bug Office and mobile number only set to be undefined to fix formkit bug. Once formkit resolves bug, remove both of these if statements
  if (!data.complainantMobileNumber) {
    data.complainantMobileNumber = undefined;
  }
  if (!data.complainantOfficeNumber) {
    data.complainantOfficeNumber = undefined;
  }
}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: any) {
  //TODO: TS
  addressFromFormkit(data as IComplaint, "complainantMail");
  //TODO: Mask Bug Office and mobile number only set to be undefined to fix formkit bug. Once formkit resolves bug, remove both of these if statements
  if (!data.complainantMobileNumber) {
    data.complainantMobileNumber = null;
  }
  if (!data.complainantOfficeNumber) {
    data.complainantOfficeNumber = null;
  }
}
