import { RouterView } from "vue-router";
import { EntityDocuments } from "@modernary/mui/components/document";
import { EntityNotes } from "@modernary/mui/components/note";

// Troubleshoot your routes using this - https://paths.esm.dev

const names = {
  swmps: "Swmps",
  swmpList: "SwmpList",
  swmpNew: "SwmpNew",
  swmpBase: "SwmpBase",
  swmpView: "SwmpView",
  swmpEdit: "SwmpEdit",
  swmpNotes: "SwmpNotes",
  swmpNoteEdit: "SwmpNoteEdit",
  swmpNoteNew: "SwmpNoteNew",
  swmpDocuments: "SwmpDocuments",
  swmpDocumentEdit: "SwmpDocumentEdit",
  swmpDocumentNew: "SwmpDocumentNew",
};

export { names as swmpRouteNames };

/** @type {import('vue-router').RouterOptions['routes']} */
const swmpRoutes = [
  {
    path: "/swmps",
    name: names.swmps,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.swmpList },
    children: [
      {
        path: "list",
        name: names.swmpList,
        meta: { title: "Solid Waste Management Plans" },
        component: () => import("@/views/swmp/SwmpList.vue"),
      },
      {
        path: ":id(\\d+)",
        name: names.swmpBase,
        component: () => import("@/views/swmp/SwmpBase.vue"),
        redirect: { name: names.swmpView },
        children: [
          {
            path: "view",
            name: names.swmpView,
            meta: { title: "SWMP View" },
            component: () => import("@/views/swmp/SwmpView.vue"),
          },
          {
            path: "notes",
            name: names.swmpNotes,
            meta: { title: "SWMP Internal Notes" },
            component: {
              render: () => <EntityNotes idField="swmpId" routeNamePrefix="SwmpNote" />,
            },
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.swmpNoteEdit,
            meta: { title: "Edit SWMP Internal Note", isForm: true },
            component: () => import("@/views/swmp/SwmpNote.vue"),
          },
          {
            path: "notes/new",
            name: names.swmpNoteNew,
            meta: { title: "Create SWMP Internal Note", isForm: true },
            component: () => import("@/views/swmp/SwmpNote.vue"),
          },
          {
            path: "documents",
            name: names.swmpDocuments,
            meta: { title: "SWMP Documents" },
            component: {
              render: () => <EntityDocuments idField="swmpId" routeNamePrefix="SwmpDocument" />,
            },
          },
          {
            path: "documents/:documentId(\\d+)",
            name: names.swmpDocumentEdit,
            meta: { title: "Edit SWMP Document", isForm: true },
            component: () => import("@/views/swmp/SwmpDocument.vue"),
          },
          {
            path: "documents/new",
            name: names.swmpDocumentNew,
            meta: { title: "Create SWMP Document", isForm: true },
            component: () => import("@/views/swmp/SwmpDocument.vue"),
          },
        ],
      },
    ],
  },
];
export default swmpRoutes;
