import { NotifyOptionsSuccess, NotifyOptionsError, INotifierConfig } from "@modernary/mui/components/notifications";
import { apiService } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";

const URL = "custom-analytics";
const ENTITY_NAME = "Custom Analytics";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};

const api = apiService(apiHosts.SMART, axiosParams);

/**
 * @param {String} name Report name
 * @param {Object} params Report query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 * @param {ApiOptions} config
 */
const getCustomReportByName = (name: string, params: any, abortMethod: Function, config: INotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + name, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

// const exportCustomReportByName = async (name, fileName, params, abortMethod, config = {}) => {
//   const successNotifCb = (options: NotifyOptionsSuccess) => {
//     options.hide = true;
//     config?.successNotifCb?.(options);
//   };
//   const errorNotifCb = (options: NotifyOptionsError) => {
//     options.fetched = ENTITY_NAME;
//     config?.errorNotifCb?.(options)
//   };

//   if (!params.format) {
//     params.format = "csv"; // default export format.
//   }

//   const result = await api.get(URL + "/" + name, {
//     params,
//     paramsSerializer: serializeQueryParams,
//     abort: abortMethod,
//     successNotifCb,
//     errorNotifCb,
//   });

//   const hiddenElement = document.createElement("a");
//   hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(result.data);
//   hiddenElement.target = "_blank";
//   hiddenElement.download = fileName;
//   hiddenElement.click();
//   hiddenElement.remove();
// };

export {
  //exportCustomReportByName,
  getCustomReportByName,
};
