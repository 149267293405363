import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiService, getOtherQueryIdStrings, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { IAffiliation, IAffiliationNew } from "@/views/affiliation/affiliation";
import type { AxiosResponse } from "axios";

const URL = "affiliations";
const ENTITY_NAME = "Affiliation";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<IAffiliation, IPaging<IAffiliation>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getAffiliations = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const getAffiliationById = (ids: any = {}, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const { id, ...otherIds } = ids;
  const otherIdStrings = getOtherQueryIdStrings(otherIds);

  return api.get(URL + "/" + id + "?" + otherIdStrings, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Object} relatedEntityIds Key-value pairs with entity IDs. E.g. { reportId: 123 }
 */
const getNewAffiliation = async (relatedEntityIds: any) => {
  //TODO:TS
  //optionally make any other changes as necessary before returning.
  const defaultAffiliation = { person: {}, ...relatedEntityIds };
  return { data: defaultAffiliation } as AxiosResponse<IAffiliationNew, any>;
};

const createAffiliation = (affiliation: IAffiliation, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, affiliation, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateAffiliation = (affiliation: IAffiliation, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + affiliation.id, affiliation, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Affiliation} affiliation This cannot be a 'ref' to a affiliation
 */
const saveAffiliation = (affiliation: IAffiliation, config: IPagingApiAndNotifierConfig = {}) => {
  if (affiliation.id) {
    return updateAffiliation(affiliation, config);
  } else {
    return createAffiliation(affiliation, config);
  }
};

/**
 * @param {Int} id
 */
const deleteAffiliationById = (ids: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const { id, ...otherIds } = ids;
  const otherIdStrings = getOtherQueryIdStrings(otherIds);

  return api.delete(URL + "/" + ids.id + "?" + otherIdStrings, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteAffiliationById,
  getAffiliations,
  getAffiliationById,
  getNewAffiliation,
  createAffiliation,
  updateAffiliation,
  saveAffiliation,
};
