import reportScheduleService from "@/views/permitVersion/reportScheduleService";
import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiService, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { getPermitVersionById } from "@/views/permitVersion/permitVersionApi";
import { IReportSchedule, IReportScheduleNew } from "@/views/permitVersion/permitVersion";
import type { AxiosResponse } from "axios";

const URL = "report-schedules";
const ENTITY_NAME = "Report Schedule";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<IReportSchedule, IPaging<IReportSchedule>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getReportSchedules = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL;
  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const getReportScheduleById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewReportSchedule = async (ids: any) => {
  //TODO:TS
  //optionally make any other changes as necessary before returning.
  const defaultApp = {
    data: {
      ...reportScheduleService.getInitialData(),
      ...ids,
      //receivedDate: new Date().toISOString().split("T")[0],
    },
  } as AxiosResponse<IReportScheduleNew, any>;

  if (ids.permitVersionId) {
    const permitVersion = await getPermitVersionById(ids.permitVersionId);
    //TODO:TS this was previously hiding all notifs
    defaultApp.data.permitVersion = permitVersion?.data;
  }

  return defaultApp;
};

/**
 *
 * @param {Object} ids Object having either an `id` for an ReportSchedule or a `permitVersionId`, `permitId`, and type
 * @param {ApiOptions} config
 * @returns {api.get}
 */

const createReportSchedule = (reportSchedule: IReportSchedule, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, reportSchedule, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateReportSchedule = (reportSchedule: IReportSchedule, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + reportSchedule.id, reportSchedule, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {ReportSchedule} reportSchedule This cannot be a 'ref' to a reportSchedule
 */
const saveReportSchedule = (reportSchedule: IReportSchedule, config: IPagingApiAndNotifierConfig = {}) => {
  if (reportSchedule.id) {
    return updateReportSchedule(reportSchedule, config);
  } else {
    return createReportSchedule(reportSchedule, config);
  }
};

/**
 * @param {Int} id
 */
const deleteReportScheduleById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const importReportSchedule = (importSchedule: IReportSchedule, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS is IReportSchedule correct for importSchedule?
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.title = ENTITY_NAME + " Imported";
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL + "/import", importSchedule, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteReportScheduleById,
  getReportSchedules,
  getReportScheduleById,
  getNewReportSchedule,
  createReportSchedule,
  updateReportSchedule,
  saveReportSchedule,
  importReportSchedule,
};
