import { RouterView } from "vue-router";
import EntityAudits from "@/components/EntityAudits.vue";
import { EntityNotes } from "@modernary/mui/components/note";

const names = {
  facilitySites: "FacilitySites",
  facilitySiteList: "FacilitySiteList",
  facilitySiteNew: "FacilitySiteNew",
  facilitySiteBase: "FacilitySiteBase",
  facilitySiteView: "FacilitySiteView",
  facilitySiteEdit: "FacilitySiteEdit",
  facilitySiteAffiliations: "FacilitySiteAffiliations",
  facilitySiteAffiliationEdit: "FacilitySiteAffiliationEdit",
  facilitySiteAffiliationNew: "FacilitySiteAffiliationNew",
  facilitySiteAffiliationSelect: "FacilitySiteAffiliationSelect",
  facilitySiteNotes: "FacilitySiteNotes",
  facilitySiteNoteEdit: "FacilitySiteNoteEdit",
  facilitySiteNoteNew: "FacilitySiteNoteNew",
  facilitySiteMaterialsAccepted: "FacilitySiteMaterialsAccepted",
  facilitySiteMaterialsAcceptedEdit: "FacilitySiteMaterialsAcceptedEdit",
  facilitySiteDisposalReports: "FacilitySiteDisposalReports",
  facilitySiteInspections: "FacilitySiteInspections",
  facilitySiteApplications: "FacilitySiteApplications",
  facilitySiteComplaints: "FacilitySiteComplaints",
  facilitySiteUsers: "FacilitySiteUsers",
  facilitySiteUserNew: "FacilitySiteUserNew",
  facilitySiteAudit: "FacilitySiteAudit",
};

export { names as facilitySiteRouteNames };

const facilitySiteRoutes = [
  {
    path: "/facilitySites",
    name: names.facilitySites,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.facilitySiteList },
    children: [
      {
        path: "list",
        name: names.facilitySiteList,
        meta: { title: "Facilities" },
        component: () => import("@/views/facilitySite/FacilitySiteList.vue"),
      },
      {
        path: "new",
        component: () => import("@/views/facilitySite/FacilitySiteBase.vue"),
        redirect: { name: names.facilitySiteNew },
        props: true,
        children: [
          {
            path: "",
            name: names.facilitySiteNew,
            meta: { title: "New Facility", isNew: true },
            component: () => import("@/views/facilitySite/FacilitySiteEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.facilitySiteBase,
        component: () => import("@/views/facilitySite/FacilitySiteBase.vue"),
        redirect: { name: names.facilitySiteView },
        children: [
          {
            path: "view",
            name: names.facilitySiteView,
            meta: { title: "Facility View" },
            component: () => import("@/views/facilitySite/FacilitySiteView.vue"),
          },
          {
            path: "edit",
            name: names.facilitySiteEdit,
            meta: { title: "Facility Edit", isForm: true },
            component: () => import("@/views/facilitySite/FacilitySiteEdit.vue"),
          },
          {
            path: "affiliations",
            name: names.facilitySiteAffiliations,
            meta: { title: "Facility Site Affiliations" },
            component: () => import("@/views/facilitySite/FacilitySiteAffiliations.vue"),
          },
          {
            path: "affiliations/:affiliationId(\\d+)/:entityType(organization|person)",
            name: names.facilitySiteAffiliationEdit,
            meta: { title: "Edit Facility Site Affiliation", isForm: true },
            component: () => import("@/views/facilitySite/FacilitySiteAffiliation.vue"),
          },
          {
            path: "affiliations/new/:entityType(organization|person)",
            name: names.facilitySiteAffiliationNew,
            meta: { title: "Create Facility Site Affiliation", isForm: true },
            component: () => import("@/views/facilitySite/FacilitySiteAffiliation.vue"),
          },
          {
            path: "affiliations/select",
            name: names.facilitySiteAffiliationSelect,
            meta: { title: "Create Facility Site Affiliation", isForm: true },
            component: () => import("@/views/facilitySite/FacilitySiteAffiliationSelect.vue"),
          },
          {
            path: "notes",
            name: names.facilitySiteNotes,
            meta: { title: "Facility Site Internal Notes" },
            component: {
              render: () => <EntityNotes idField="facilitySiteId" routeNamePrefix="FacilitySiteNote" />,
            },
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.facilitySiteNoteEdit,
            meta: { title: "Edit Facility Site Internal Note", isForm: true },
            component: () => import("@/views/facilitySite/FacilitySiteNote.vue"),
          },
          {
            path: "notes/new",
            name: names.facilitySiteNoteNew,
            meta: { title: "Create Facility Site Internal Note", isForm: true },
            component: () => import("@/views/facilitySite/FacilitySiteNote.vue"),
          },
          {
            path: "materialsAccepted",
            name: names.facilitySiteMaterialsAccepted,
            meta: { title: "Facility Site Material Accepted" },
            component: () => import("@/views/facilitySite/FacilitySiteMaterialsAccepted.vue"),
          },
          {
            path: "materialsAccepted/edit",
            name: names.facilitySiteMaterialsAcceptedEdit,
            meta: { title: "Edit Facility Site Material Accepted", isForm: true },
            component: () => import("@/views/facilitySite/FacilitySiteMaterialsAcceptedEdit.vue"),
          },
          {
            path: "disposalReports",
            name: names.facilitySiteDisposalReports,
            meta: { title: "Facility Site Disposal Reports" },
            component: () => import("@/views/facilitySite/DisposalReportChildList.vue"),
          },
          {
            path: "inspections",
            name: names.facilitySiteInspections,
            meta: { title: "Facility Site Inspections" },
            component: () => import("@/views/facilitySite/InspectionsChildList.vue"),
          },
          {
            path: "applications",
            name: names.facilitySiteApplications,
            meta: { title: "Facility Site Applications" },
            component: () => import("@/views/facilitySite/ApplicationsChildList.vue"),
          },
          {
            path: "complaints",
            name: names.facilitySiteComplaints,
            meta: { title: "Facility Site Complaints" },
            component: () => import("@/views/facilitySite/ComplaintsChildList.vue"),
          },
          {
            path: "users",
            name: names.facilitySiteUsers,
            meta: { title: "Facility Site Users" },
            component: () => import("@/views/facilitySite/FacilitySiteUsers.vue"),
          },
          {
            path: "users/new",
            name: names.facilitySiteUserNew,
            meta: { title: "Add Facility Site User", isForm: true },
            component: () => import("@/views/facilitySite/FacilitySiteUser.vue"),
          },
          {
            path: "activity",
            name: names.facilitySiteAudit,
            meta: { title: "Facility Site Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="FacilitySite" />,
            },
          },
          // {
          //   path: "documents",
          //   name: "FacilitySiteDocuments",
          //   meta: { title: "Facility Documents" },
          //   component: { render: () => <div>docs</div> },
          // },
        ],
      },
    ],
  },
];
export default facilitySiteRoutes;
