import { apiService } from "@modernary/mui/utils/api";

import { apiHosts } from "@/api/constants/apiHosts";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const entityAuditApi = apiService(apiHosts.SMART, axiosParams);

export { entityAuditApi };
