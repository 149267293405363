import { IDisposalReport } from "@/views/disposalReport/disposalReport";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
};

function addAuthRequestFields(entity: IDisposalReport) {
  entity._authRequestFields = ["id", "facilitySiteId", "status", "type", "year"];
  return entity;
}

function getInitialData() {
  const result = { type: "DisposalReport", status: "Scheduled", disposedWastes: [] } as unknown as IDisposalReport;
  //TODO:TS
  return addAuthRequestFields(result);
}

const validNumberInputRegex = /^[0-9]\d{0,2}(\d{3})*(\.\d+)?$/;
const tonnageChangeHandler = (disposalReport: IDisposalReport) => {
  const newTotal = disposalReport.disposedWastes.reduce(
    (prev, curr) =>
      curr.tonnage && validNumberInputRegex.test(curr.tonnage?.toString())
        ? prev + parseFloat(curr.tonnage?.toString())
        : prev,
    0,
  );

  disposalReport.totalTons = newTotal;
};

function responseAdapter(response: { data: IDisposalReport }) {
  tonnageChangeHandler(response.data);
  addAuthRequestFields(response.data);

  response.data.disposedWastes?.forEach((x) => {
    x.key = x.id;
  });

  return response.data;
}
function requestAdapter() {}
