import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiService, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import type { AxiosResponse } from "axios";
import { IMaterialPermitted } from "./materialPermitted";

const PARENT_URL = (parentId: string | number, parentEntity: string) =>
  `${parentEntity}/${parentId}/materials-permitted`;
const URL = "materials-permitted";

const ENTITY_NAME = "Material Permitted";
const PARENT_ENTITY = {
  permitVersion: "permit-versions",
};

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<IMaterialPermitted, IPaging<IMaterialPermitted>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 * @param {ApiOptions} config
 */
const getMaterialsPermitted = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL;
  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const createMaterialPermittedForPermitVersion = (materialPermitted: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME; // Spcial case where a "Create" is always treated as an Update because it performs CUD.
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(PARENT_URL(materialPermitted.permitVersionId, PARENT_ENTITY.permitVersion), materialPermitted, {
    successNotifCb,
    errorNotifCb,
  });
};

export { getMaterialsPermitted, createMaterialPermittedForPermitVersion };
