import organizationService from "@/views/organization/organizationService";
import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiService, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { CanDeleteOrganization, IOrganization, IOrganizationNew } from "@/views/organization/organization";
import type { AxiosResponse } from "axios";

const URL = "organizations";
const ENTITY_NAME = "Organization";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<IOrganization, IPaging<IOrganization>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getOrganizations = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL;
  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const getOrganizationById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewOrganization = async () => {
  //optionally make any other changes as necessary before returning.
  return { data: organizationService.getInitialData() } as AxiosResponse<IOrganizationNew, any>;
};

const createOrganization = (organization: IOrganization, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, organization, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateOrganization = (organization: IOrganization, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + organization.id, organization, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Organization} organization This cannot be a 'ref' to a organization
 */
const saveOrganization = (organization: IOrganization, config: IPagingApiAndNotifierConfig = {}) => {
  if (organization.id) {
    return updateOrganization(organization, config);
  } else {
    return createOrganization(organization, config);
  }
};

/**
 * @param {Int} id
 */
const deleteOrganizationById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const canDeleteApi = apiService<CanDeleteOrganization, IPaging<CanDeleteOrganization>>(apiHosts.SMART, axiosParams);
const canDeleteOrganization = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return canDeleteApi.get(URL + "/can-delete/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteOrganizationById,
  getOrganizations,
  getOrganizationById,
  getNewOrganization,
  createOrganization,
  updateOrganization,
  saveOrganization,
  canDeleteOrganization,
};
