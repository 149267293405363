import { RouterView } from "vue-router";
import EntityAudits from "@/components/EntityAudits.vue";
import { EntityNotes } from "@modernary/mui/components/note";
import { EntityDocuments } from "@modernary/mui/components/document";

// Troubleshoot your routes using this - https://paths.esm.dev

const names = {
  applications: "Applications",
  applicationList: "ApplicationList",
  applicationNew: "ApplicationNew",
  applicationNewSelect: "ApplicationNewSelect",
  applicationBase: "ApplicationBase",
  applicationView: "ApplicationView",
  applicationEdit: "ApplicationEdit",
  applicationComplete: "ApplicationComplete",
  applicationWithdraw: "ApplicationWithdraw",
  applicationNotes: "ApplicationNotes",
  applicationNoteEdit: "ApplicationNoteEdit",
  applicationNoteNew: "ApplicationNoteNew",
  applicationDocuments: "ApplicationDocuments",
  applicationDocumentEdit: "ApplicationDocumentEdit",
  applicationDocumentNew: "ApplicationDocumentNew",
  applicationAudit: "ApplicationAudit",
};

export { names as applicationRouteNames };

/** @type {import('vue-router').RouterOptions['routes']} */
const applicationRoutes = [
  {
    path: "/applications",
    name: names.applications,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.applicationList },
    children: [
      {
        path: "list",
        name: names.applicationList,
        meta: { title: "Applications" },
        component: () => import("@/views/application/ApplicationList.vue"),
      },
      {
        path: "new/:type(Initial|Modification|Renewal)?/:facilitySiteId(\\d+)?/:permitId(\\d+)?",
        component: () => import("@/views/application/ApplicationBase.vue"),
        redirect: { name: names.applicationNew },
        props: true,
        children: [
          {
            path: "",
            name: names.applicationNew,
            meta: { title: "New Application", isNew: true },
            component: () => import("@/views/application/ApplicationEdit.vue"),
          },
          {
            path: "select",
            name: names.applicationNewSelect,
            meta: { title: "New Application", isNew: true },
            component: () => import("@/views/application/ApplicationSelect.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.applicationBase,
        component: () => import("@/views/application/ApplicationBase.vue"),
        redirect: { name: names.applicationView },
        children: [
          {
            path: "view",
            name: names.applicationView,
            meta: { title: "Application View" },
            component: () => import("@/views/application/ApplicationView.vue"),
          },
          {
            path: "edit",
            name: names.applicationEdit,
            meta: { title: "Application Edit", isForm: true },
            component: () => import("@/views/application/ApplicationEdit.vue"),
          },
          {
            path: "complete",
            name: names.applicationComplete,
            meta: { title: "Complete Application", isForm: true },
            component: () => import("@/views/application/ApplicationComplete.vue"),
          },
          {
            path: "withdraw",
            name: names.applicationWithdraw,
            meta: { title: "Withdraw Application", isForm: true },
            component: () => import("@/views/application/ApplicationWithdraw.vue"),
          },
          {
            path: "notes",
            name: names.applicationNotes,
            meta: { title: "Application Internal Notes" },
            component: {
              render: () => <EntityNotes idField="applicationId" routeNamePrefix="ApplicationNote" />,
            },
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.applicationNoteEdit,
            meta: { title: "Edit Application Internal Note", isForm: true },
            component: () => import("@/views/application/ApplicationNote.vue"),
          },
          {
            path: "notes/new",
            name: names.applicationNoteNew,
            meta: { title: "Create Application Internal Note", isForm: true },
            component: () => import("@/views/application/ApplicationNote.vue"),
          },
          {
            path: "documents",
            name: names.applicationDocuments,
            meta: { title: "Application Documents" },
            component: {
              render: () => <EntityDocuments idField="applicationId" routeNamePrefix="ApplicationDocument" />,
            },
          },
          {
            path: "documents/:documentId(\\d+)",
            name: names.applicationDocumentEdit,
            meta: { title: "Edit Application Document", isForm: true },
            component: () => import("@/views/application/ApplicationDocument.vue"),
          },
          {
            path: "documents/new",
            name: names.applicationDocumentNew,
            meta: { title: "Create Application Document", isForm: true },
            component: () => import("@/views/application/ApplicationDocument.vue"),
          },
          {
            path: "activity",
            name: names.applicationAudit,
            meta: { title: "Application Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="Application" />,
            },
          },
        ],
      },
    ],
  },
];
export default applicationRoutes;
