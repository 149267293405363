import { RouterView } from "vue-router";

const names = {
  analytics: "Analytics",
  analyticsDashboard: "AnalyticsDashboard",
  disposedWasteReport: "DisposedWasteReport",
  divertedMaterialReport: "DivertedMaterialReport",
  generatedWasteReport: "GeneratedWasteReport",
  exportRecyclingReportData: "ExportRecyclingReportData",
  materialCategoryReport: "MaterialCategoryReport",
  organizationMaterialReport: "OrganizationMaterialReport",
  recycledMaterialReport: "RecycledMaterialReport",
  recyclingRateReport: "RecyclingRateReport",
  emailList: "EmailList",
};

export { names as analyticsRouteNames };

const analyticsRoutes = [
  {
    path: "/analytics",
    name: names.analytics,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.analyticsDashboard },
    children: [
      {
        path: "dashboard",
        name: names.analyticsDashboard,
        meta: { title: "Analytics" },
        component: () => import("@/views/analytics/Index.vue"),
      },
      {
        path: "",
        name: "no_visit_analytics", //Vue router insists we have a name in this case; we'll never use it, but it must still be unique.
        component: () => import("@/views/analytics/AnalyticsBase.vue"),
        abstract: true,
        children: [
          // {
          //   path: "disposedWasteReport",
          //   name: names.disposedWasteReport,
          //   meta: { title: "Disposed Waste Report" },
          //   component: () => import("@/views/analytics/DisposedWasteReport.vue"),
          // },
          {
            path: "divertedMaterialReport",
            name: names.divertedMaterialReport,
            meta: { title: "Diverted Material Report" },
            component: () => import("@/views/analytics/DivertedMaterialReport.vue"),
          },
          {
            path: "generatedWasteReport",
            name: names.generatedWasteReport,
            meta: { title: "Generated Waste Report" },
            component: () => import("@/views/analytics/GeneratedWasteReport.vue"),
          },
          {
            path: "exportRecyclingReportData",
            name: names.exportRecyclingReportData,
            meta: { title: "Recycling Report Data Export", hideFilterChange: true },
            component: () => import("@/views/analytics/ExportRecyclingReportData.vue"),
          },
          {
            path: "materialCategoryReport",
            name: names.materialCategoryReport,
            meta: { title: "Material Category Report" },
            component: () => import("@/views/analytics/MaterialCategoryReport.vue"),
          },
          {
            path: "organizationMaterialReport",
            name: names.organizationMaterialReport,
            meta: { title: "Organization Material Report" },
            component: () => import("@/views/analytics/OrganizationMaterialReport.vue"),
          },
          {
            path: "recycledMaterialReport",
            name: names.recycledMaterialReport,
            meta: { title: "Recycled Material Report" },
            component: () => import("@/views/analytics/RecycledMaterialReport.vue"),
          },
          {
            path: "recyclingRateReport",
            name: names.recyclingRateReport,
            meta: { title: "Recycling Rate Report" },
            component: () => import("@/views/analytics/RecyclingRateReport.vue"),
          },
          {
            path: "emailList",
            name: names.emailList,
            meta: { title: "Email List Report" },
            component: () => import("@/views/analytics/EmailList.vue"),
          },
        ],
      },
    ],
  },
];
export default analyticsRoutes;
