import { RouterView } from "vue-router";
import EntityAudits from "@/components/EntityAudits.vue";
import { EntityNotes } from "@modernary/mui/components/note";
// Troubleshoot your routes using this - https://paths.esm.dev

const names = {
  recyclingReports: "RecyclingReports",
  recyclingReportList: "RecyclingReportList",
  recyclingReportNew: "RecyclingReportNew",
  recyclingReportBase: "RecyclingReportBase",
  recyclingReportView: "RecyclingReportView",
  recyclingReportEdit: "RecyclingReportEdit",
  recyclingReportNotes: "RecyclingReportNotes",
  recyclingReportNoteEdit: "RecyclingReportNoteEdit",
  recyclingReportNoteNew: "RecyclingReportNoteNew",
  recyclingReportAudit: "RecyclingReportAudit",
};

export { names as recyclingReportRouteNames };

/** @type {import('vue-router').RouterOptions['routes']} */
const recyclingReportRoutes = [
  {
    path: "/recyclingReports",
    name: names.recyclingReports,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.recyclingReportList },
    children: [
      {
        path: "list",
        name: names.recyclingReportList,
        meta: { title: "Recycling Reports" },
        component: () => import("@/views/recyclingReport/RecyclingReportList.vue"),
      },
      {
        path: "new/:organizationId(\\d+)?",
        component: () => import("@/views/recyclingReport/RecyclingReportBase.vue"),
        redirect: { name: names.recyclingReportNew },
        props: true,
        children: [
          {
            path: "",
            name: names.recyclingReportNew,
            meta: { title: "Create Recycling Report", isNew: true },
            component: () => import("@/views/recyclingReport/RecyclingReportEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.recyclingReportBase,
        component: () => import("@/views/recyclingReport/RecyclingReportBase.vue"),
        redirect: { name: names.recyclingReportView },
        children: [
          {
            path: "view",
            name: names.recyclingReportView,
            meta: { title: "Recycling Report View" },
            component: () => import("@/views/recyclingReport/RecyclingReportView.vue"),
          },
          {
            path: "edit",
            name: names.recyclingReportEdit,
            meta: { title: "Recycling Report Edit", isForm: true },
            component: () => import("@/views/recyclingReport/RecyclingReportEdit.vue"),
          },
          {
            path: "notes",
            name: names.recyclingReportNotes,
            meta: { title: "Recycling Report Internal Notes" },
            component: {
              render: () => <EntityNotes idField="reportId" routeNamePrefix="RecyclingReportNote" />,
            },
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.recyclingReportNoteEdit,
            meta: { title: "Edit Recycling Report Internal Note", isForm: true },
            component: () => import("@/views/recyclingReport/RecyclingReportNote.vue"),
          },
          {
            path: "notes/new",
            name: names.recyclingReportNoteNew,
            meta: { title: "Create Recycling Report Internal Note", isForm: true },
            component: () => import("@/views/recyclingReport/RecyclingReportNote.vue"),
          },
          {
            path: "activity",
            name: names.recyclingReportAudit,
            meta: { title: "Recycling Report Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="RecyclingReport" />,
            },
          },
        ],
      },
    ],
  },
];
export default recyclingReportRoutes;
