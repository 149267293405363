// import documentService from "@/services/documentService";
import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiService, getOtherQueryIdStrings, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { IDocument } from "@/views/document/document";
import type { AxiosResponse } from "axios";

const URL = "documents";
const ENTITY_NAME = "Document";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<IDocument, IPaging<IDocument>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getDocuments = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const getDocumentDownloadLink = (id: string | number) => {
  return axiosParams.baseURL + URL + "/" + id + "/download";
};

/**
 * @param {Int} id
 */
const getDocumentById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewDocument = async (ids: any) => {
  //TODO:TS
  return { data: { ...ids } } as AxiosResponse<IDocument, any>;
};

const createDocument = (document: IDocument, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, document, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateDocument = (document: IDocument, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + document.id, document, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Document} document This cannot be a 'ref' to a document
 */
const saveDocument = (document: IDocument, config: IPagingApiAndNotifierConfig = {}) => {
  if (document.id) {
    return updateDocument(document, config);
  } else {
    return createDocument(document, config);
  }
};

/**
 * @param {Int} id
 */
const deleteDocumentById = (ids: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const otherIdStrings = getOtherQueryIdStrings(ids);
  return api.delete(URL + "/" + ids.id + "?" + otherIdStrings, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  getDocumentDownloadLink,
  deleteDocumentById,
  getDocuments,
  getDocumentById,
  getNewDocument,
  createDocument,
  updateDocument,
  saveDocument,
};
