import recyclingReportService from "@/views/recyclingReport/recyclingReportService";
import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiService, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { getOrganizationById } from "@/views/organization/organizationApi";
import { IRecyclingReport, IRecyclingReportNew } from "@/views/recyclingReport/recyclingReport";
import type { AxiosResponse } from "axios";

const URL = "recycling-reports";
const ENTITY_NAME = "Recycling Report";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<IRecyclingReport, IPaging<IRecyclingReport>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 * @param {ApiOptions} config
 */
const getRecyclingReports = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL;
  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 * @param {ApiOptions} config
 * @returns {api.get}
 */
const getRecyclingReportById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewRecyclingReport = async (organizationId: string | number) => {
  //optionally make any other changes as necessary before returning.
  return getOrganizationById(organizationId).then((result) => {
    //TODO:TS this was previously hiding all notifs
    const defaultRR = recyclingReportService.getInitialData();
    defaultRR.organization = result.data;
    defaultRR.organizationId = result.data.id;
    defaultRR.year = new Date().getFullYear() - 1;
    return { data: defaultRR } as AxiosResponse<IRecyclingReportNew, any>;
  });
};

/**
 *
 * @param {Object} ids Object having either an `id` for a RecyclingReport or an `organizationId`
 * @param {ApiOptions} config
 * @returns {api.get}
 */

const createRecyclingReport = (recyclingReport: IRecyclingReport, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, recyclingReport, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateRecyclingReport = (recyclingReport: IRecyclingReport, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + recyclingReport.id, recyclingReport, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {RecyclingReport} recyclingReport This cannot be a 'ref' to a recyclingReport
 */
const saveRecyclingReport = (recyclingReport: IRecyclingReport, config: IPagingApiAndNotifierConfig = {}) => {
  if (recyclingReport.id) {
    return updateRecyclingReport(recyclingReport, config);
  } else {
    return createRecyclingReport(recyclingReport, config);
  }
};

/**
 * @param {Int} id
 */
const deleteRecyclingReportById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteRecyclingReportById,
  getRecyclingReports,
  getRecyclingReportById,
  getNewRecyclingReport,
  createRecyclingReport,
  updateRecyclingReport,
  saveRecyclingReport,
};
