<template>
  <Badge :label="disposalReportStatuses.get(labelId).description" :class="[variant && $style[variant]]"></Badge>
</template>

<script lang="ts">
//Example of how you'd use the Badge component to make self-styling status badges.
import { PropType, computed, defineComponent } from "vue";
import { Badge } from "@modernary/mui/components/badge";
import { DisposalReportStatus, enums } from "@/constants/enums";

export default defineComponent({
  name: "DisposalReportStatusBadge",
  props: {
    labelId: {
      type: String as PropType<DisposalReportStatus>,
      required: true,
      validator: (val: any) => ["Scheduled", "Draft", "Submitted", "Verified", "PastDue"].includes(val) || !val,
    },
  },
  setup(props) {
    const variants = {
      Scheduled: "scheduled",
      Draft: "draft",
      Submitted: "submitted",
      Verified: "verified",
      PastDue: "past-due",
    };

    const variant = computed(() => variants[props.labelId]);

    return { variant, disposalReportStatuses: enums.disposalReportStatuses };
  },
  components: { Badge },
});
</script>

<style module>
.scheduled {
  @apply bg-orange-200 text-orange-800;
}
.draft {
  @apply bg-neutral-200 text-neutral-800;
}
.submitted {
  @apply bg-cyan-100 text-cyan-800;
}
.verified {
  @apply bg-green-100 text-green-800;
}
.past-due {
  @apply bg-red-100 text-red-800;
}
</style>
