<template>
  <router-view />
  <BaseNotifications></BaseNotifications>
</template>

<script setup lang="tsx">
import { computed } from "vue";
import { useRoute } from "vue-router";
import { BaseNotifications } from "@modernary/mui/components/notifications";
import { useAppNav } from "@modernary/mui/components/common";

import { routeNames } from "@/routes";
import { NAV_ANALYTICS } from "./constants/symbols";

const route = useRoute();
const { setNavigationItems, setApplicationTitleComponent } = useAppNav();

const titleComponent = () => <div class="ml-3 font-bold text-neutral-500 md:ml-4 md:text-xl">SMART</div>;
setApplicationTitleComponent(titleComponent);

// Nav items should be set one time during app initilization / config.
setNavigationItems([
  {
    label: "home",
    className: "",
    showLabel: false,
    items: [{ label: "Dashboard", name: routeNames.home }],
  },
  {
    label: "Compliance",
    className: "",
    items: [
      { label: "Complaints", name: routeNames.complaints },
      { label: "Inspections", name: routeNames.inspections },
    ],
  },
  {
    label: "Permitting",
    className: "",
    items: [
      { label: "Applications", name: routeNames.applications },
      { label: "Permits", name: routeNames.permits },
      { label: "Permit Versions", name: routeNames.permitVersions },
      { label: "Facilities", name: routeNames.facilitySites },
    ],
  },
  {
    label: "Reporting",
    className: "",
    items: [
      { label: "Disposal Reports", name: routeNames.disposalReports },
      { label: "Recycling Reports", name: routeNames.recyclingReports },
      { label: "Reports", name: routeNames.reports },
    ],
  },
  {
    label: "misc",
    className: "3xl:break-before-column",
    showLabel: false,
    items: [
      { label: "Analytics", name: routeNames.analytics },
      { label: "Contacts", name: routeNames.persons },
      { label: "Organizations", name: routeNames.organizations },
      { label: "SWMP", name: routeNames.swmps },
    ],
  },
]);

//NAV_ANALYTICS
setNavigationItems(
  [
    {
      label: "Recycling",
      className: "",
      showLabel: true,
      items: [
        {
          label: "Diverted Material Report",
          name: routeNames.divertedMaterialReport,
          description: "Recycling",
        },
        {
          label: "Material Category Report",
          name: routeNames.materialCategoryReport,
          description: "Recycling",
        },
        {
          label: "Organization Material Report",
          name: routeNames.organizationMaterialReport,
          description: "Recycling",
        },
        {
          label: "Recycled Material Report",
          name: routeNames.recycledMaterialReport,
          description: "Recycling",
        },
        {
          label: "Recycling Rate Report",
          name: routeNames.recyclingRateReport,
          description: "Recycling",
        },
        {
          label: "Recycling Report Data Export",
          name: routeNames.exportRecyclingReportData,
          description: "Recycling",
        },
      ],
    },
    {
      label: "Disposal",
      className: "",
      items: [
        // {
        //   label: "Disposed Waste Report",
        //   name: routeNames.disposedWasteReport,
        //   description: "Disposal",
        // },
        {
          label: "Generated Waste Report",
          name: routeNames.generatedWasteReport,
          description: "Disposal",
        },
      ],
    },
    {
      label: "Email",
      className: "",
      items: [
        {
          label: "Email List Report",
          name: routeNames.emailList,
          description: "Email",
        },
      ],
    },
  ],
  NAV_ANALYTICS,
);
</script>
