import { NotifyOptionsSuccess, NotifyOptionsError, INotifierConfig } from "@modernary/mui/components/notifications";

import { apiService } from "@modernary/mui/utils/api";
import { apiHosts } from "@/api/constants/apiHosts";

const URL = "access-requests";
const ENTITY_NAME = "Request";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService(apiHosts.SMART, axiosParams);

const createAccessRequest = (accessRequest: any, config: INotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.title = "Request Submitted";
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, accessRequest, {
    successNotifCb,
    errorNotifCb,
  });
};

export { createAccessRequest };
