<template>
  <Badge :label="recyclingReportStatuses.get(labelId).description" :class="[variant && $style[variant]]"></Badge>
</template>

<script lang="ts">
//Example of how you'd use the Badge component to make self-styling status badges.
import { PropType, computed, defineComponent } from "vue";
import { Badge } from "@modernary/mui/components/badge";

import { RecyclingReportStatus, enums } from "@/constants/enums";

export default defineComponent({
  name: "RecyclingReportStatusBadge",
  props: {
    labelId: {
      type: String as PropType<RecyclingReportStatus>,
      required: true,
      validator: (val: any) => ["Draft", "Submitted", "Verified"].includes(val) || !val,
    },
  },
  setup(props) {
    const variants = {
      Draft: "draft",
      Submitted: "submitted",
      Verified: "verified",
    };

    const variant = computed(() => variants[props.labelId]);

    return { variant, recyclingReportStatuses: enums.recyclingReportStatuses };
  },
  components: { Badge },
});
</script>

<style module>
.draft {
  @apply bg-neutral-200 text-neutral-800;
}
.submitted {
  @apply bg-cyan-100 text-cyan-800;
}
.verified {
  @apply bg-green-100 text-green-800;
}
</style>
