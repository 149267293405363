import {
  addressFromFormkit,
  addressToFormkit,
  plainAddressFromFormkit,
  plainAddressToFormkit,
} from "@modernary/mui/components/fk";
import { sortByAlphabeticalByField } from "@modernary/mui/utils/helpers";
import { formatNumber } from "@modernary/mui/utils/filters";
import { IOrganization } from "@/views/organization/organization";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: IOrganization) {
  entity._authRequestFields = ["id"];
  return entity;
}
function getInitialData() {
  const result = {} as IOrganization; //TODO:TS
  return addAuthRequestFields(result);
}
function responseAdapter(response: { data: IOrganization }) {
  addAuthRequestFields(response.data);
  return response.data;
}
function requestAdapter() {}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit(data: IOrganization) {
  addressToFormkit(data, "mail");
}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: any) {
  //TODO:TS
  addressFromFormkit(data as IOrganization, "mail");
}
