import Home from "./views/Home.vue";

import { NotAuthorized, NotFound } from "@modernary/mui/components/common";
import { EntityAuditDetailed } from "@modernary/mui/components/audit";
import { default as analyticsRoutes, analyticsRouteNames } from "./views/analytics/analyticsRoutes";
import { default as applicationRoutes, applicationRouteNames } from "./views/application/applicationRoutes";
import { default as complaintRoutes, complaintRouteNames } from "./views/complaint/complaintRoutes";
import { default as disposalReportRoutes, disposalReportRouteNames } from "./views/disposalReport/disposalReportRoutes";
import { default as facilitySiteRoutes, facilitySiteRouteNames } from "./views/facilitySite/facilitySiteRoutes";
import { default as inspectionRoutes, inspectionRouteNames } from "./views/inspection/inspectionRoutes";
// import loginRoutes from "./views/login/loginRoutes";
import { default as organizationRoutes, organizationRouteNames } from "./views/organization/organizationRoutes";
import { default as permitRoutes, permitRouteNames } from "./views/permit/permitRoutes";
import { default as permitVersionRoutes, permitVersionRouteNames } from "./views/permitVersion/permitVersionRoutes";
import { default as personRoutes, personRouteNames } from "./views/person/personRoutes";
import {
  default as recyclingReportRoutes,
  recyclingReportRouteNames,
} from "./views/recyclingReport/recyclingReportRoutes";
import { default as reportRoutes, reportRouteNames } from "./views/report/reportRoutes";
import { default as swmpRoutes, swmpRouteNames } from "./views/swmp/swmpRoutes";
import { enums } from "./constants/enums";
import { RouterOptions } from "vue-router";
// Troubleshoot your routes using this - https://paths.esm.dev

export const routeNames = {
  home: "Home",
  notAuthorized: "NotAuthorized",
  ...analyticsRouteNames,
  ...applicationRouteNames,
  ...complaintRouteNames,
  ...disposalReportRouteNames,
  ...facilitySiteRouteNames,
  ...inspectionRouteNames,
  ...organizationRouteNames,
  ...permitRouteNames,
  ...permitVersionRouteNames,
  ...personRouteNames,
  ...recyclingReportRouteNames,
  ...reportRouteNames,
  ...swmpRouteNames,
};

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
  { path: "/", component: Home, name: routeNames.home, meta: { title: "Home" } },
  {
    path: "/unauthorized",
    component: NotAuthorized,
    name: routeNames.notAuthorized,
    meta: { title: "Not Authorized" },
  },
  {
    path: "/detailedAudit",
    name: "EntityAuditDetailed",
    meta: { title: "Detailed Audit History", isForm: true },
    component: {
      render: () => <EntityAuditDetailed entityTypes={enums.entityTypes.toDropDown()} />,
    },
  },
  ...analyticsRoutes,
  ...applicationRoutes,
  ...complaintRoutes,
  ...personRoutes,
  ...disposalReportRoutes,
  ...facilitySiteRoutes,
  ...inspectionRoutes,
  // ...loginRoutes,
  ...organizationRoutes,
  ...permitRoutes,
  ...permitVersionRoutes,
  ...recyclingReportRoutes,
  ...reportRoutes,
  ...swmpRoutes,
  { path: "/:path(.*)", component: NotFound },
];
