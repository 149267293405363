import { RouterView } from "vue-router";
import EntityAudits from "@/components/EntityAudits.vue";
import { EntityDocuments } from "@modernary/mui/components/document";
import { EntityNotes } from "@modernary/mui/components/note";

// Troubleshoot your routes using this - https://paths.esm.dev

const names = {
  permits: "Permits",
  permitList: "PermitList",
  permitNew: "PermitNew",
  permitBase: "PermitBase",
  permitView: "PermitView",
  permitNotes: "PermitNotes",
  permitNoteEdit: "PermitNoteEdit",
  permitNoteNew: "PermitNoteNew",
  permitDocuments: "PermitDocuments",
  permitDocumentEdit: "PermitDocumentEdit",
  permitDocumentNew: "PermitDocumentNew",
  permitFinancialAssurances: "PermitFinancialAssurances",
  permitFinancialAssuranceEdit: "PermitFinancialAssuranceEdit",
  permitFinancialAssuranceNew: "PermitFinancialAssuranceNew",
  permitDisposalReports: "PermitDisposalReports",
  permitReports: "PermitReports",
  permitAudit: "PermitAudit",
};

export { names as permitRouteNames };

/** @type {import('vue-router').RouterOptions['routes']} */
const permitRoutes = [
  {
    path: "/permits",
    name: names.permits,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.permitList },
    children: [
      {
        path: "list",
        name: names.permitList,
        meta: { title: "Permits" },
        component: () => import("@/views/permit/PermitList.vue"),
      },
      {
        path: ":id(\\d+)",
        name: names.permitBase,
        component: () => import("@/views/permit/PermitBase.vue"),
        redirect: { name: names.permitView },
        children: [
          {
            path: "view",
            name: names.permitView,
            meta: { title: "Permit View" },
            component: () => import("@/views/permit/PermitView.vue"),
          },
          {
            path: "notes",
            name: names.permitNotes,
            meta: { title: "Permit Internal Notes" },
            component: {
              render: () => <EntityNotes idField="permitId" routeNamePrefix="PermitNote" />,
            },
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.permitNoteEdit,
            meta: { title: "Edit Permit Internal Note", isForm: true },
            component: () => import("@/views/permit/PermitNote.vue"),
          },
          {
            path: "notes/new",
            name: names.permitNoteNew,
            meta: { title: "Create Permit Internal Note", isForm: true },
            component: () => import("@/views/permit/PermitNote.vue"),
          },
          {
            path: "documents",
            name: names.permitDocuments,
            meta: { title: "Permit Documents" },
            component: {
              render: () => <EntityDocuments idField="permitId" routeNamePrefix="PermitDocument" />,
            },
          },
          {
            path: "documents/:documentId(\\d+)",
            name: names.permitDocumentEdit,
            meta: { title: "Edit Permit Document", isForm: true },
            component: () => import("@/views/permit/PermitDocument.vue"),
          },
          {
            path: "documents/new",
            name: names.permitDocumentNew,
            meta: { title: "Create Permit Document", isForm: true },
            component: () => import("@/views/permit/PermitDocument.vue"),
          },
          {
            path: "financialAssurances",
            name: names.permitFinancialAssurances,
            meta: { title: "Permit Financial Assurance" },
            component: () => import("@/views/permit/PermitFinancialAssurances.vue"),
          },
          {
            path: "financialAssurances/:financialAssuranceId(\\d+)",
            name: names.permitFinancialAssuranceEdit,
            meta: { title: "Edit Permit Financial Assurance", isForm: true },
            component: () => import("@/views/permit/PermitFinancialAssurance.vue"),
          },
          {
            path: "financialAssurances/new",
            name: names.permitFinancialAssuranceNew,
            meta: { title: "Create Permit Financial Assurance", isForm: true },
            component: () => import("@/views/permit/PermitFinancialAssurance.vue"),
          },
          {
            path: "reports",
            name: names.permitReports,
            meta: { title: "Permit Reports" },
            component: () => import("@/views/permit/ReportsChildList.vue"),
          },
          {
            path: "disposalReports",
            name: names.permitDisposalReports,
            meta: { title: "Permit Disposal Reports" },
            component: () => import("@/views/permit/DisposalReportChildList.vue"),
          },
          {
            path: "activity",
            name: names.permitAudit,
            meta: { title: "Permit Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="Permit" />,
            },
          },
        ],
      },
    ],
  },
];
export default permitRoutes;
