import { RouterView } from "vue-router";
import EntityAudits from "@/components/EntityAudits.vue";
import { EntityDocuments } from "@modernary/mui/components/document";
import { EntityNotes } from "@modernary/mui/components/note";

// Troubleshoot your routes using this - https://paths.esm.dev

const names = {
  reports: "Reports",
  reportList: "ReportList",
  reportNew: "ReportNew",
  reportNewSchedule: "ReportNewSchedule",
  reportNewSelect: "ReportNewSelect",
  reportBase: "ReportBase",
  reportView: "ReportView",
  reportEdit: "ReportEdit",
  reportSubmit: "ReportSubmit",
  // reportComplete: "ReportComplete",
  // reportWithdraw: "ReportWithdraw",
  reportNotes: "ReportNotes",
  reportNoteEdit: "ReportNoteEdit",
  reportNoteNew: "ReportNoteNew",
  reportDocuments: "ReportDocuments",
  reportDocumentEdit: "ReportDocumentEdit",
  reportDocumentNew: "ReportDocumentNew",
  reportAudit: "ReportAudit",
};

export { names as reportRouteNames };

/** @type {import('vue-router').RouterOptions['routes']} */
const reportRoutes = [
  {
    path: "/reports",
    name: names.reports,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.reportList },
    children: [
      {
        path: "list",
        name: names.reportList,
        meta: { title: "Reports" },
        component: () => import("@/views/report/ReportList.vue"),
      },
      {
        path: "new/:permitId(\\d+)?",
        component: () => import("@/views/report/ReportBase.vue"),
        redirect: { name: names.reportNew },
        props: true,
        children: [
          {
            path: ":reportScheduleId(\\d+)?",
            name: names.reportNew,
            meta: { title: "New Report", isNew: true },
            component: () => import("@/views/report/ReportEdit.vue"),
          },
          {
            path: "schedule/:previousReportId(\\d+)?/:reportScheduleId(\\d+)?",
            name: names.reportNewSchedule,
            meta: { title: "Schedule Next Report", isSchedule: true, isForm: true },
            component: () => import("@/views/report/ReportEdit.vue"),
          },
          {
            path: "select",
            name: names.reportNewSelect,
            meta: { title: "New Report", isNew: true },
            component: () => import("@/views/report/ReportSelect.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.reportBase,
        component: () => import("@/views/report/ReportBase.vue"),
        redirect: { name: names.reportView },
        children: [
          {
            path: "view",
            name: names.reportView,
            meta: { title: "Report View" },
            component: () => import("@/views/report/ReportView.vue"),
          },
          {
            path: "edit",
            name: names.reportEdit,
            meta: { title: "Report Edit", isForm: true },
            component: () => import("@/views/report/ReportEdit.vue"),
          },
          {
            path: "receive",
            name: names.reportSubmit,
            meta: { title: "Submit Report", isForm: true },
            component: () => import("@/views/report/ReportSubmit.vue"),
          },
          // {
          //   path: "complete",
          //   name: names.reportComplete,
          //   meta: { title: "Complete Report", isForm: true },
          //   component: () => import("@/views/report/ReportComplete.vue"),
          // },
          // {
          //   path: "withdraw",
          //   name: names.reportWithdraw,
          //   meta: { title: "Withdraw Report", isForm: true },
          //   component: () => import("@/views/report/ReportWithdraw.vue"),
          // },
          {
            path: "notes",
            name: names.reportNotes,
            meta: { title: "Report Internal Notes" },
            component: {
              render: () => <EntityNotes idField="reportId" routeNamePrefix="ReportNote" />,
            },
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.reportNoteEdit,
            meta: { title: "Edit Report Internal Note", isForm: true },
            component: () => import("@/views/report/ReportNote.vue"),
          },
          {
            path: "notes/new",
            name: names.reportNoteNew,
            meta: { title: "Create Report Internal Note", isForm: true },
            component: () => import("@/views/report/ReportNote.vue"),
          },
          {
            path: "documents",
            name: names.reportDocuments,
            meta: { title: "Report Documents" },
            component: {
              render: () => <EntityDocuments idField="reportId" routeNamePrefix="ReportDocument" />,
            },
          },
          {
            path: "documents/:documentId(\\d+)",
            name: names.reportDocumentEdit,
            meta: { title: "Edit Report Document", isForm: true },
            component: () => import("@/views/report/ReportDocument.vue"),
          },
          {
            path: "documents/new",
            name: names.reportDocumentNew,
            meta: { title: "Create Report Document", isForm: true },
            component: () => import("@/views/report/ReportDocument.vue"),
          },
          {
            path: "activity",
            name: names.reportAudit,
            meta: { title: "Report Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="Report" />,
            },
          },
        ],
      },
    ],
  },
];
export default reportRoutes;
