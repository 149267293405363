import applicationService from "@/views/application/applicationService";
import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiService, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { getFacilitySiteById } from "@/views/facilitySite/facilitySiteApi";
import { getPermitById } from "../permit/permitApi";
import { IApplication, IApplicationNew } from "@/views/application/application";
import type { AxiosResponse } from "axios";

const URL = "applications";
const ENTITY_NAME = "Application";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<IApplication, IPaging<IApplication>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getApplications = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getApplicationById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewApplication = async (ids: any) => {
  //TODO: ts
  //optionally make any other changes as necessary before returning.
  const defaultApp = {
    data: {
      ...applicationService.getInitialData(),
      ...ids,
      //receivedDate: new Date().toISOString().split("T")[0],
    },
  } as AxiosResponse<IApplicationNew, any>;

  if (ids.facilitySiteId || ids.permitId) {
    const permit = ids.permitId && getPermitById(ids.permitId);
    const facilitySite = ids.facilitySiteId && getFacilitySiteById(ids.facilitySiteId);
    //TODO:TS this was previously passing in a bool to hide all notifs when fetching the FacilitySite

    return Promise.all([permit, facilitySite]).then(([permitData, fsData]) => {
      defaultApp.data.permit = permitData?.data;
      defaultApp.data.facilitySite = fsData?.data;
      return defaultApp;
    });
  } else {
    return defaultApp;
  }
};

/**
 *
 * @param {Object} ids Object having either an `id` for an Application or a `facilitySiteId`, `permitId`, and type
 * @param {ApiOptions} config
 * @returns {api.get}
 */

const createApplication = (application: IApplication, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, application, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateApplication = (application: IApplication, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + application.id, application, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Application} application This cannot be a 'ref' to a application
 */
const saveApplication = (application: IApplication, config = {}) => {
  if (application.id) {
    return updateApplication(application, config);
  } else {
    return createApplication(application, config);
  }
};

/**
 * @param {Int} id
 */
const deleteApplicationById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteApplicationById,
  getApplications,
  getApplicationById,
  getNewApplication,
  createApplication,
  updateApplication,
  saveApplication,
};
