/* 
  Insertion order determines presentation order on the UI. Keep the key equal to the "id". 
  Use the functions to get a new copy of an enum when you intend to modify it (e.g. toggle isInactive)  
*/
/*
  Reserved field names:
  isHiddenInSelect - used to hide options from SelectInput
  isInactive - used to disable an option in SelectInput while keeping selected if that's the current value.
*/

import { createEnum } from "@modernary/mui/utils/helpers";

export interface IEnumRecord {
  id: string;
  description: string;
  [key: string]: any;
}

export interface IEnumMap extends Map<string, IEnumRecord> {}

export type IEnums = {
  [Property in keyof typeof enumsCopy]: ReturnType<(typeof enumsCopy)[Property]>;
};

// Access to the methods that generate the enums, useful for modifying the properties on the enums without having to "deep copy" first.
export const enumsCopy = {
  affiliationFacilityTypes,
  affiliationOrganizationTypes,
  affiliationInspectionTypes,
  applicationStatuses,
  applicationTypes,
  complaintInvestigationActions,
  complaintInvesetigationStatuses,
  complaintStatuses,
  complaintTypes,
  disposalReportFrequencies,
  disposalReportStatuses,
  disposalWasteTypes,
  documentCategories,
  entityTypes,
  emailCategories,
  facilitySiteTypes,
  facilitySiteStatuses,
  haulerTypes,
  inspectionTypes,
  inspectionStatuses,
  instrumentTypes,
  materialCategories,
  nevadaCounties,
  permitStatuses,
  permitTypes,
  permitVersionStatuses,
  permitVersionTypes,
  recyclingCategories,
  recyclingReportStatuses,
  reportStatuses,
  reportTypes,
  reportFrequencies,
  reportPeriods,
  stateAbbreviations,
  wasteCounties,
};

// TODO: Wrap the function calls in readonly?
// Reusable, "standard" enums.
export const enums = Object.entries(enumsCopy).reduce((enumObj, [key, func]) => {
  enumObj[key as keyof IEnums] = func() as any; // bypass TS stupidity.
  return enumObj;
}, {} as IEnums);

export type AffiliationFacilityTypes = "Facility Contact" | "Facility Operator" | "Facility Owner";
export function affiliationFacilityTypes() {
  return createEnum(
    new Map<AffiliationFacilityTypes, { id: string; description: string }>([
      [
        "Facility Contact",
        {
          id: "Facility Contact",
          description: "Facility Contact",
        },
      ],
      [
        "Facility Operator",
        {
          id: "Facility Operator",
          description: "Facility Operator",
        },
      ],
      [
        "Facility Owner",
        {
          id: "Facility Owner",
          description: "Facility Owner",
        },
      ],
    ]),
  );
}

export type AffiliationOrganizationTypes = "Organization Contact";
export function affiliationOrganizationTypes() {
  return createEnum(
    new Map<AffiliationOrganizationTypes, { id: string; description: string }>([
      [
        "Organization Contact",
        {
          id: "Organization Contact",
          description: "Organization Contact",
        },
      ],
    ]),
  );
}

export type AffiliationInspectionTypes = "Inspection Contact";
export function affiliationInspectionTypes() {
  return createEnum(
    new Map<AffiliationInspectionTypes, { id: string; description: string }>([
      [
        "Inspection Contact",
        {
          id: "Inspection Contact",
          description: "Inspection Contact",
        },
      ],
    ]),
  );
}

export type ApplicationStatus = "Pending" | "Complete" | "Withdrawn";
export function applicationStatuses() {
  return createEnum(
    new Map<ApplicationStatus, { id: string; description: string }>([
      [
        "Pending",
        {
          id: "Pending",
          description: "Pending",
        },
      ],
      [
        "Complete",
        {
          id: "Complete",
          description: "Complete",
        },
      ],
      [
        "Withdrawn",
        {
          id: "Withdrawn",
          description: "Withdrawn",
        },
      ],
    ]),
  );
}

export type ApplicationTypes = "Initial" | "Modification" | "Renewal";
export function applicationTypes() {
  return createEnum(
    new Map<ApplicationTypes, { id: string; description: string }>([
      [
        "Initial",
        {
          id: "Initial",
          description: "Permit Application",
        },
      ],
      [
        "Modification",
        {
          id: "Modification",
          description: "Permit Modification Application",
        },
      ],
      [
        "Renewal",
        {
          id: "Renewal",
          description: "Permit Renewal Application",
        },
      ],
    ]),
  );
}

export type ComplaintInvestigationActions = "None Taken" | "Warning Letter" | "Verbal Warning" | "FOAV/Order";
export function complaintInvestigationActions() {
  return createEnum(
    new Map<ComplaintInvestigationActions, { id: string; description: string }>([
      [
        "None Taken",
        {
          id: "None Taken",
          description: "None Taken",
        },
      ],
      [
        "Verbal Warning",
        {
          id: "Verbal Warning",
          description: "Verbal Warning",
        },
      ],
      [
        "Warning Letter",
        {
          id: "Warning Letter",
          description: "Warning Letter",
        },
      ],
      [
        "FOAV/Order",
        {
          id: "FOAV/Order",
          description: "FOAV/Order",
        },
      ],
    ]),
  );
}

export type ComplaintInvesetigationStatus = "Open" | "Closed";
export function complaintInvesetigationStatuses() {
  return createEnum(
    new Map<ComplaintInvesetigationStatus, { id: string; description: string }>([
      [
        "Open",
        {
          id: "Open",
          description: "Open",
        },
      ],
      [
        "Closed",
        {
          id: "Closed",
          description: "Closed",
        },
      ],
    ]),
  );
}

export type ComplaintStatus = "Open" | "Closed";
export function complaintStatuses() {
  return createEnum(
    new Map<ComplaintStatus, { id: string; description: string }>([
      [
        "Open",
        {
          id: "Open",
          description: "Open",
        },
      ],
      [
        "Closed",
        {
          id: "Closed",
          description: "Closed",
        },
      ],
    ]),
  );
}

export type ComplaintTypes =
  | "Litter"
  | "Scrap Yard"
  | "Burial"
  | "Abandoned Vehicles"
  | "Appliances"
  | "C&D Debris"
  | "Dead Animals"
  | "Electronics"
  | "Furniture"
  | "Hazardous Items"
  | "Tires";
export function complaintTypes() {
  return createEnum(
    new Map<ComplaintTypes, { id: string; description: string }>([
      [
        "Litter",
        {
          id: "Litter",
          description: "Litter",
        },
      ],
      [
        "Scrap Yard",
        {
          id: "Scrap Yard",
          description: "Scrap Yard",
        },
      ],
      [
        "Burial",
        {
          id: "Burial",
          description: "Burial",
        },
      ],
      [
        "Abandoned Vehicles",
        {
          id: "Abandoned Vehicles",
          description: "Abandoned Vehicles",
        },
      ],
      [
        "Appliances",
        {
          id: "Appliances",
          description: "Appliances",
        },
      ],
      [
        "C&D Debris",
        {
          id: "C&D Debris",
          description: "C&D Debris",
        },
      ],
      [
        "Dead Animals",
        {
          id: "Dead Animals",
          description: "Dead Animals",
        },
      ],
      [
        "Electronics",
        {
          id: "Electronics",
          description: "Electronics",
        },
      ],
      [
        "Furniture",
        {
          id: "Furniture",
          description: "Furniture",
        },
      ],
      [
        "Hazardous Items",
        {
          id: "Hazardous Items",
          description: "Hazardous Items",
        },
      ],
      [
        "Tires",
        {
          id: "Tires",
          description: "Tires",
        },
      ],
    ]),
  );
}

export type DisposalReportFrequencies = "Annual" | "SemiAnnual" | "Quarter";
export function disposalReportFrequencies() {
  return createEnum(
    new Map<DisposalReportFrequencies, { id: string; description: string; periods: string[] }>([
      [
        "Annual",
        {
          id: "Annual",
          description: "Annual",
          periods: ["A"],
        },
      ],
      [
        "SemiAnnual",
        {
          id: "SemiAnnual",
          description: "Semi-Annual",
          periods: ["H1", "H2"],
        },
      ],
      [
        "Quarter",
        {
          id: "Quarter",
          description: "Quarterly",
          periods: ["Q1", "Q2", "Q3", "Q4"],
        },
      ],
    ]),
  );
}

export type DisposalReportStatus = "Scheduled" | "Draft" | "Submitted" | "Verified" | "PastDue";
export function disposalReportStatuses() {
  return createEnum(
    new Map<DisposalReportStatus, { id: string; description: string }>([
      [
        "Scheduled",
        {
          id: "Scheduled",
          description: "Scheduled",
        },
      ],
      [
        "Draft",
        {
          id: "Draft",
          description: "Draft",
        },
      ],
      [
        "Submitted",
        {
          id: "Submitted",
          description: "Submitted",
        },
      ],
      [
        "Verified",
        {
          id: "Verified",
          description: "Verified",
        },
      ],
      // [
      //   "PastDue",
      //   {
      //     id: "PastDue",
      //     description: "Past Due",
      //   },
      // ],
    ]),
  );
}

export type DisposalWasteTypes =
  | "MSW"
  | "Industrial"
  | "Asbestos"
  | "C&D"
  | "Medical"
  | "Tires"
  | "Autofluff"
  | "Sludge"
  | "HC Contaminated"
  | "Concrete over 12 inches"
  | "Concrete less than 12 inches"
  | "Concrete w/rebar"
  | "Tires (chopped/shredded)"
  | "Fly Ash"
  | "Bottom Ash"
  | "Ash"
  | "Diverted Material";
export function disposalWasteTypes() {
  return createEnum(
    new Map<DisposalWasteTypes, { id: string; description: string; group: string }>([
      [
        "MSW",
        {
          id: "MSW",
          description: "MSW",
          group: "MSW",
        },
      ],
      [
        "Industrial",
        {
          id: "Industrial",
          description: "Industrial",
          group: "Industrial",
        },
      ],
      [
        "Asbestos",
        {
          id: "Asbestos",
          description: "Asbestos",
          group: "Industrial",
        },
      ],
      [
        "C&D",
        {
          id: "C&D",
          description: "C&D",
          group: "Industrial",
        },
      ],
      [
        "Medical",
        {
          id: "Medical",
          description: "Medical",
          group: "Industrial",
        },
      ],
      [
        "Tires",
        {
          id: "Tires",
          description: "Tires",
          group: "Industrial",
        },
      ],
      [
        "Autofluff",
        {
          id: "Autofluff",
          description: "Autofluff",
          group: "Industrial",
        },
      ],
      [
        "Sludge",
        {
          id: "Sludge",
          description: "Sludge",
          group: "Industrial",
        },
      ],
      [
        "HC Contaminated",
        {
          id: "HC Contaminated",
          description: "HC Contaminated",
          group: "Industrial",
        },
      ],
      [
        "Concrete over 12 inches",
        {
          id: "Concrete over 12 inches",
          description: "Concrete over 12 inches",
          group: "Industrial",
        },
      ],
      [
        "Concrete less than 12 inches",
        {
          id: "Concrete less than 12 inches",
          description: "Concrete less than 12 inches",
          group: "Industrial",
        },
      ],
      [
        "Concrete w/rebar",
        {
          id: "Concrete w/rebar",
          description: "Concrete w/rebar",
          group: "Industrial",
        },
      ],
      [
        "Tires (chopped/shredded)",
        {
          id: "Tires (chopped/shredded)",
          description: "Tires (chopped/shredded)",
          group: "Industrial",
        },
      ],
      [
        "Fly Ash",
        {
          id: "Fly Ash",
          description: "Fly Ash",
          group: "Industrial",
        },
      ],
      [
        "Bottom Ash",
        {
          id: "Bottom Ash",
          description: "Bottom Ash",
          group: "Industrial",
        },
      ],
      [
        "Ash",
        {
          id: "Ash",
          description: "Ash",
          group: "Industrial",
        },
      ],
      [
        "Diverted Material",
        {
          id: "Diverted Material",
          description: "Diverted Material",
          group: "Industrial",
        },
      ],
    ]),
  );
}

export type DocumentCategories =
  | "Application"
  | "Complaint"
  | "Inspection"
  | "Permit"
  | "PermitVersion"
  | "SWMP"
  | "Report";
export function documentCategories() {
  return createEnum(
    new Map<DocumentCategories, { id: string; description: string; types: any }>([
      [
        "Application",
        {
          id: "Application",
          description: "Application",
          types: new Map([
            ["Application Form", { id: "Application Form", description: "Application Form", extensions: ".pdf" }],
          ]),
        },
      ],
      [
        "Complaint",
        {
          id: "Complaint",
          description: "Complaint",
          types: new Map([
            ["Complaint Document", { id: "Complaint Document", description: "Complaint Document", extensions: ".pdf" }],
            [
              "State Complaint Spill Report Form",
              {
                id: "State Complaint Spill Report Form",
                description: "State Complaint/Spill Report Form",
                extensions: ".pdf",
              },
            ],
          ]),
        },
      ],
      [
        "Inspection",
        {
          id: "Inspection",
          description: "Inspection",
          types: new Map([
            ["Report", { id: "Report", description: "Report", extensions: ".pdf" }],
            ["Correspondence", { id: "Correspondence", description: "Correspondence", extensions: ".pdf" }],
            ["Photograph", { id: "Photograph", description: "Photograph", extensions: ".gif,.jpg,.png" }],
          ]),
        },
      ],
      [
        "Permit",
        {
          id: "Permit",
          description: "Permit",
          types: new Map([
            ["Permit Document", { id: "Permit Document", description: "Permit Document", extensions: ".pdf" }],
          ]),
        },
      ],
      [
        "PermitVersion",
        {
          id: "PermitVersion",
          description: "Permit Version",
          types: new Map([
            [
              "Permit Version Document",
              {
                id: "Permit Version Document",
                description: "Permit Version Document",
                extensions: ".pdf",
              },
            ],
          ]),
        },
      ],
      [
        "SWMP",
        {
          id: "SWMP",
          description: "SWMP",
          types: new Map([
            [
              "Solid Waste Management Plan",
              {
                id: "Solid Waste Management Plan",
                description: "Solid Waste Management Plan",
                extensions: ".pdf",
              },
            ],
          ]),
        },
      ],
      [
        "Report",
        {
          id: "Report",
          description: "Report",
          types: new Map([
            [
              "Report Document",
              {
                id: "Report Document",
                description: "Report Document",
                extensions: ".pdf",
              },
            ],
          ]),
        },
      ],
    ]),
  );
}

export type EmailCategories = "Facility" | "Organization";
export function emailCategories() {
  return createEnum(
    new Map<EmailCategories, { id: string; description: string }>([
      [
        "Facility",
        {
          id: "Facility",
          description: "Facility",
        },
      ],
      [
        "Organization",
        {
          id: "Organization",
          description: "Organization",
        },
      ],
    ]),
  );
}

export type EntityTypes =
  | "GenericReport"
  | "RecyclingReport"
  | "PermitVersion"
  | "Permit"
  | "Organization"
  | "Inspection"
  | "FacilitySite"
  | "DisposalReport"
  | "Complaint"
  | "Application"
  | "Person"
  | "Affiliation"
  | "InspectionChecklist"
  | "Document"
  | "Annotation"
  | "FacilitySiteUser"
  | "FinancialAssurance"
  | "MaterialAccepted"
  | "OrganizationUser"
  | "ReportSchedule";

//person, affiliation
export function entityTypes() {
  return createEnum(
    new Map<EntityTypes, { id: string; description: string }>([
      [
        "Application",
        {
          id: "application",
          description: "Application",
        },
      ],
      [
        "Complaint",
        {
          id: "complaint",
          description: "Complaint",
        },
      ],
      [
        "DisposalReport",
        {
          id: "disposalReport",
          description: "Disposal Report",
        },
      ],
      [
        "FacilitySite",
        {
          id: "facilitySite",
          description: "Facility Site",
        },
      ],
      [
        "GenericReport",
        {
          id: "genericReport",
          description: "Generic Report",
        },
      ],
      [
        "Inspection",
        {
          id: "inspection",
          description: "Inspection",
        },
      ],
      [
        "Organization",
        {
          id: "organization",
          description: "Organization",
        },
      ],
      [
        "Permit",
        {
          id: "permit",
          description: "Permit",
        },
      ],
      [
        "PermitVersion",
        {
          id: "permitVersion",
          description: "Permit Version",
        },
      ],
      [
        "RecyclingReport",
        {
          id: "recyclingReport",
          description: "Recycling Report",
        },
      ],
      [
        "Person",
        {
          id: "person",
          description: "Person",
        },
      ],
      [
        "Affiliation",
        {
          id: "affiliation",
          description: "affiliation",
        },
      ],
      [
        "InspectionChecklist",
        {
          id: "inspectionChecklist",
          description: "Inspection Checklist",
        },
      ],
      [
        "Document",
        {
          id: "document",
          description: "Document",
        },
      ],
      [
        "Annotation",
        {
          id: "annotation",
          description: "Annotation",
        },
      ],
      [
        "FacilitySiteUser",
        {
          id: "facilitySiteUser",
          description: "Facility Site User",
        },
      ],
      [
        "FinancialAssurance",
        {
          id: "financialAssurance",
          description: "Financial Assurance",
        },
      ],
      [
        "MaterialAccepted",
        {
          id: "materialAccepted",
          description: "Material Accepted",
        },
      ],
      [
        "OrganizationUser",
        {
          id: "organizationUser",
          description: "Organization User",
        },
      ],
      [
        "ReportSchedule",
        {
          id: "reportSchedule",
          description: "Report Schedule",
        },
      ],
    ]),
  );
}

export type FacilitySiteTypes =
  | "Class I"
  | "Class I and III"
  | "Class II"
  | "Class II and III"
  | "Class III"
  | "Class III Waiver"
  | "Compost Facility"
  | "Incinerator"
  | "Mailing Group"
  | "Material Recovery Facility"
  | "Open Dump"
  | "Process Facility"
  | "Public Waste Bin"
  | "Recycling Facility"
  | "SWWaiver"
  | "Salvage Yard"
  | "Transfer Station"
  | "Tribal Facility"
  | "Waste Tire Facility"
  | "WasteTireHauler";
export function facilitySiteTypes() {
  return createEnum(
    new Map<FacilitySiteTypes, { id: string; description: string; inspectionType: InspectionTypes }>([
      ["Class I", { id: "Class I", description: "Class I", inspectionType: "Landfill" }],
      ["Class I and III", { id: "Class I and III", description: "Class I and III", inspectionType: "Landfill" }],
      ["Class II", { id: "Class II", description: "Class II", inspectionType: "Landfill" }],
      ["Class II and III", { id: "Class II and III", description: "Class II and III", inspectionType: "Landfill" }],
      ["Class III", { id: "Class III", description: "Class III", inspectionType: "Landfill" }],
      [
        "Class III Waiver",
        {
          id: "Class III Waiver",
          description: "Class III Waiver",
          inspectionType: "WaiveredLandfill",
        },
      ],
      ["Compost Facility", { id: "Compost Facility", description: "Compost Facility", inspectionType: "CompostPlant" }],
      ["Incinerator", { id: "Incinerator", description: "Incinerator", inspectionType: "Other" }],
      ["Mailing Group", { id: "Mailing Group", description: "Mailing Group", inspectionType: "Other" }],
      [
        "Material Recovery Facility",
        {
          id: "Material Recovery Facility",
          description: "Material Recovery Facility",
          inspectionType: "Other",
        },
      ],
      ["Open Dump", { id: "Open Dump", description: "Open Dump", inspectionType: "Other" }],
      ["Process Facility", { id: "Process Facility", description: "Process Facility", inspectionType: "Other" }],
      ["Public Waste Bin", { id: "Public Waste Bin", description: "Public Waste Bin", inspectionType: "StorageBin" }],
      ["Recycling Facility", { id: "Recycling Facility", description: "Recycling Facility", inspectionType: "Other" }],
      ["Salvage Yard", { id: "Salvage Yard", description: "Salvage Yard", inspectionType: "Other" }],
      ["SWWaiver", { id: "SWWaiver", description: "SWWaiver", inspectionType: "Other" }],
      [
        "Transfer Station",
        {
          id: "Transfer Station",
          description: "Transfer Station",
          inspectionType: "TransferStation",
        },
      ],
      ["Tribal Facility", { id: "Tribal Facility", description: "Tribal Facility", inspectionType: "Other" }],
      [
        "Waste Tire Facility",
        {
          id: "Waste Tire Facility",
          description: "Waste Tire Facility",
          inspectionType: "WasteTire",
        },
      ],
      ["WasteTireHauler", { id: "WasteTireHauler", description: "Waste Tire Hauler", inspectionType: "Other" }],
    ]),
  );
}

export type FacilitySiteStatuses =
  | "Application Withdrawn"
  | "Closed"
  | "Mail List Active"
  | "Not Operating - Permitted"
  | "Operating - Permitted"
  | "Operating - Pmt Not Reqd"
  | "Post Closure"
  | "Post Closure Permit"
  | "Recycler"
  | "Reviewing Application"
  | "Unapproved Facility"
  | "Unknown";
export function facilitySiteStatuses() {
  return createEnum(
    new Map<FacilitySiteStatuses, { id: string; description: string }>([
      ["Application Withdrawn", { id: "Application Withdrawn", description: "Application Withdrawn" }],
      ["Closed", { id: "Closed", description: "Closed" }],
      ["Mail List Active", { id: "Mail List Active", description: "Mail List Active" }],
      ["Not Operating - Permitted", { id: "Not Operating - Permitted", description: "Not Operating - Permitted" }],
      ["Operating - Permitted", { id: "Operating - Permitted", description: "Operating - Permitted" }],
      ["Operating - Pmt Not Reqd", { id: "Operating - Pmt Not Reqd", description: "Operating - Pmt Not Reqd" }],
      ["Post Closure", { id: "Post Closure", description: "Post Closure" }],
      ["Post Closure Permit", { id: "Post Closure Permit", description: "Post Closure Permit" }],
      ["Recycler", { id: "Recycler", description: "Recycler" }],
      ["Reviewing Application", { id: "Reviewing Application", description: "Reviewing Application" }],
      ["Unapproved Facility", { id: "Unapproved Facility", description: "Unapproved Facility" }],
      ["Unknown", { id: "Unknown", description: "Unknown" }],
    ]),
  );
}

export type HaulderTypes = "CommercialCompacted" | "CommercialUncompacted" | "PrivateUncompacted";
export function haulerTypes() {
  return createEnum(
    new Map<HaulderTypes, { id: string; description: string; group: string }>([
      [
        "CommercialCompacted",
        {
          id: "CommercialCompacted",
          description: "Commercial Compacted",
          group: "Compacted",
        },
      ],
      [
        "CommercialUncompacted",
        {
          id: "CommercialUncompacted",
          description: "Commercial Uncompacted",
          group: "Uncompacted",
        },
      ],
      [
        "PrivateUncompacted",
        {
          id: "PrivateUncompacted",
          description: "Private Uncompacted",
          group: "Uncompacted",
        },
      ],
    ]),
  );
}

export type InspectionTypes =
  | "CompostPlant"
  | "Landfill"
  | "TransferStation"
  | "StorageBin"
  | "WasteTire"
  | "WaiveredLandfill"
  | "Other";
export function inspectionTypes() {
  return createEnum(
    new Map<InspectionTypes, { id: string; description: string }>([
      [
        "CompostPlant",
        {
          id: "CompostPlant",
          description: "Solid Waste Compost Plant",
        },
      ],
      [
        "Landfill",
        {
          id: "Landfill",
          description: "Landfill",
        },
      ],
      [
        "TransferStation",
        {
          id: "TransferStation",
          description: "Solid Waste Transfer Station",
        },
      ],
      [
        "StorageBin",
        {
          id: "StorageBin",
          description: "Public Waste Storage Bin",
        },
      ],
      [
        "WasteTire",
        {
          id: "WasteTire",
          description: "Waste Tire Management Facility",
        },
      ],
      [
        "WaiveredLandfill",
        {
          id: "WaiveredLandfill",
          description: "Waivered Class III Landfill",
        },
      ],
      [
        "Other",
        {
          id: "Other",
          description: "Other",
        },
      ],
    ]),
  );
}

export type InspectionStatus = "Due" | "Completed";
export function inspectionStatuses() {
  return createEnum(
    new Map<InspectionStatus, { id: string; description: string }>([
      [
        "Due",
        {
          id: "Due",
          description: "Due",
        },
      ],
      [
        "Completed",
        {
          id: "Completed",
          description: "Completed",
        },
      ],
    ]),
  );
}

export type InstrumentTypes =
  | "Corporate Test"
  | "Exempt"
  | "FA Update"
  | "Insurance"
  | "Letter Of Credit"
  | "Local Government Test"
  | "Surety Bond"
  | "Trust Fund";
export function instrumentTypes() {
  return createEnum(
    new Map<InstrumentTypes, { id: string; description: string }>([
      [
        "Corporate Test",
        {
          id: "Corporate Test",
          description: "Corporate Test",
        },
      ],
      [
        "Exempt",
        {
          id: "Exempt",
          description: "Exempt",
        },
      ],
      [
        "FA Update",
        {
          id: "FA Update",
          description: "FA Update",
        },
      ],
      [
        "Insurance",
        {
          id: "Insurance",
          description: "Insurance",
        },
      ],
      [
        "Letter Of Credit",
        {
          id: "Letter Of Credit",
          description: "Letter Of Credit",
        },
      ],
      [
        "Local Government Test",
        {
          id: "Local Government Test",
          description: "Local Government Test",
        },
      ],
      [
        "Surety Bond",
        {
          id: "Surety Bond",
          description: "Surety Bond",
        },
      ],
      [
        "Trust Fund",
        {
          id: "Trust Fund",
          description: "Trust Fund",
        },
      ],
    ]),
  );
}

export type MaterialCategories =
  | "C & D"
  | "Glass"
  | "Metal"
  | "Organic Material"
  | "Other Recycled MSW"
  | "Paper"
  | "Plastic"
  | "Special Waste"
  | "Textile";
export function materialCategories() {
  return createEnum(
    new Map<MaterialCategories, { id: string; description: string; types: any }>([
      [
        "C & D",
        {
          id: "C & D",
          description: "Construction & Demolition Debris",
          types: new Map([
            ["Asphalt", { id: "Asphalt", description: "Asphalt" }],
            ["Carpet", { id: "Carpet", description: "Carpet" }],
            ["Carpet Padding", { id: "Carpet Padding", description: "Carpet Padding" }],
            ["Concrete", { id: "Concrete", description: "Concrete" }],
            ["Drywall", { id: "Drywall", description: "Drywall" }],
            ["Ferrous Scrap Metals", { id: "Ferrous Scrap Metals", description: "Ferrous Scrap Metals" }],
            ["Non-ferrous Scrap Metals", { id: "Non-ferrous Scrap Metals", description: "Non-ferrous Scrap Metals" }],
            ["Plastic Buckets", { id: "Plastic Buckets", description: "Plastic Buckets" }],
            ["Wood", { id: "Wood", description: "Wood" }],
            ["Other C&D", { id: "Other C&D", description: "Other C&D" }],
          ]),
        },
      ],
      [
        "Glass",
        {
          id: "Glass",
          description: "Glass",
          types: new Map([
            ["Mixed Glass", { id: "Mixed Glass", description: "Mixed Glass" }],
            ["Other Glass", { id: "Other Glass", description: "Other Glass" }],
          ]),
        },
      ],
      [
        "Metal",
        {
          id: "Metal",
          description: "Metal",
          types: new Map([
            ["Aluminum Containers", { id: "Aluminum Containers", description: "Aluminum Containers" }],
            [
              "Appliances (white goods)",
              {
                id: "Appliances (white goods)",
                description: "Appliances (white goods)",
              },
            ],
            ["Ferrous Scrap Metals", { id: "Ferrous Scrap Metals", description: "Ferrous Scrap Metals" }],
            ["Mixed Metals", { id: "Mixed Metals", description: "Mixed Metals" }],
            [
              "Non-ferrous Scrap Metals",
              {
                id: "Non-ferrous Scrap Metals",
                description: "Non-ferrous Scrap Metals",
              },
            ],
            ["Tin/Steel Containers", { id: "Tin/Steel Containers", description: "Tin/Steel Containers" }],
            ["Other Metals", { id: "Other Metals", description: "Other Metals" }],
          ]),
        },
      ],
      [
        "Organic Material",
        {
          id: "Organic Material",
          description: "Organic Material",
          types: new Map([
            ["Biosolids", { id: "Biosolids", description: "Biosolids" }],
            ["Food Waste", { id: "Food Waste", description: "Food Waste" }],
            [
              "Pallets/Wood Packaging",
              {
                id: "Pallets/Wood Packaging",
                description: "Pallets/Wood Packaging",
              },
            ],
            [
              "Rendered Animal Matter",
              {
                id: "Rendered Animal Matter",
                description: "Rendered Animal Matter",
              },
            ],
            ["Restaurant Grease", { id: "Restaurant Grease", description: "Restaurant Grease" }],
            ["Yard Debris", { id: "Yard Debris", description: "Yard Debris" }],
            ["Other Organics", { id: "Other Organics", description: "Other Organics" }],
          ]),
        },
      ],
      [
        "Other Recycled MSW",
        {
          id: "Other Recycled MSW",
          description: "Other Recycled MSW",
          types: new Map([
            ["Electronics", { id: "Electronics", description: "Electronics" }],
            [
              "Single Stream Recycling",
              {
                id: "Single Stream Recycling",
                description: "Single Stream Recycling",
              },
            ],
            ["Toner Cartridges", { id: "Toner Cartridges", description: "Toner Cartridges" }],
            ["Other Recycled MSW", { id: "Other Recycled MSW", description: "Other Recycled MSW" }],
          ]),
        },
      ],
      [
        "Paper",
        {
          id: "Paper",
          description: "Paper",
          types: new Map([
            ["Corrugated Paper", { id: "Corrugated Paper", description: "Corrugated Paper" }],
            ["Magazines", { id: "Magazines", description: "Magazines" }],
            ["Mixed Paper", { id: "Mixed Paper", description: "Mixed Paper" }],
            ["Newspaper", { id: "Newspaper", description: "Newspaper" }],
            ["Office Paper", { id: "Office Paper", description: "Office Paper" }],
            ["Telephone Books", { id: "Telephone Books", description: "Telephone Books" }],
            ["Other Paper", { id: "Other Paper", description: "Other Paper" }],
          ]),
        },
      ],
      [
        "Plastic",
        {
          id: "Plastic",
          description: "Plastic",
          types: new Map([
            ["Mixed Plastic", { id: "Mixed Plastic", description: "Mixed Plastic" }],
            ["Plastic (HDPE)", { id: "Plastic (HDPE)", description: "Plastic (HDPE)" }],
            ["Plastic (PETE)", { id: "Plastic (PETE)", description: "Plastic (PETE)" }],
            ["Plastic Film", { id: "Plastic Film", description: "Plastic Film" }],
            ["Polystyrene", { id: "Polystyrene", description: "Polystyrene" }],
            [
              "Other (PVC, LDPE, PP)",
              {
                id: "Other (PVC, LDPE, PP)",
                description: "Other (PVC, LDPE, PP)",
              },
            ],
          ]),
        },
      ],
      [
        "Special Waste",
        {
          id: "Special Waste",
          description: "Special Waste",
          types: new Map([
            ["Fluorescent Bulbs", { id: "Fluorescent Bulbs", description: "Fluorescent Bulbs" }],
            ["Hg Devices", { id: "Hg Devices", description: "Hg Devices" }],
            [
              "Household Hazardous Waste",
              {
                id: "Household Hazardous Waste",
                description: "Household Hazardous Waste",
              },
            ],
            ["Lamp Ballasts", { id: "Lamp Ballasts", description: "Lamp Ballasts" }],
            ["Lead Acid Batteries", { id: "Lead Acid Batteries", description: "Lead Acid Batteries" }],
            ["Other Batteries", { id: "Other Batteries", description: "Other Batteries" }],
            ["Paint", { id: "Paint", description: "Paint" }],
            ["Used Antifreeze", { id: "Used Antifreeze", description: "Used Antifreeze" }],
            ["Used Oil", { id: "Used Oil", description: "Used Oil" }],
            ["Used Tires", { id: "Used Tires", description: "Used Tires" }],
            ["Other Special Waste", { id: "Other Special Waste", description: "Other Special Waste" }],
          ]),
        },
      ],
      [
        "Textile",
        {
          id: "Textile",
          description: "Textiles",
          types: new Map([
            ["Textiles", { id: "Textiles", description: "Textiles" }],
            ["Other", { id: "Other", description: "Other" }],
          ]),
        },
      ],
    ]),
  );
}

export type NevadaCounties =
  | "Carson City"
  | "Churchill"
  | "Clark"
  | "Douglas"
  | "Elko"
  | "Esmeralda"
  | "Eureka"
  | "Humboldt"
  | "Lander"
  | "Lincoln"
  | "Lyon"
  | "Mineral"
  | "Nye"
  | "Pershing"
  | "Storey"
  | "Washoe"
  | "White Pine";
export function nevadaCounties() {
  return createEnum(
    new Map<NevadaCounties, { id: string; description: string }>([
      [
        "Carson City",
        {
          id: "Carson City",
          description: "Carson City",
        },
      ],
      [
        "Churchill",
        {
          id: "Churchill",
          description: "Churchill County",
        },
      ],
      [
        "Clark",
        {
          id: "Clark",
          description: "Clark County",
        },
      ],
      [
        "Douglas",
        {
          id: "Douglas",
          description: "Douglas County",
        },
      ],
      [
        "Elko",
        {
          id: "Elko",
          description: "Elko County",
        },
      ],
      [
        "Esmeralda",
        {
          id: "Esmeralda",
          description: "Esmeralda County",
        },
      ],
      [
        "Eureka",
        {
          id: "Eureka",
          description: "Eureka County",
        },
      ],
      [
        "Humboldt",
        {
          id: "Humboldt",
          description: "Humboldt County",
        },
      ],
      [
        "Lander",
        {
          id: "Lander",
          description: "Lander County",
        },
      ],
      [
        "Lincoln",
        {
          id: "Lincoln",
          description: "Lincoln County",
        },
      ],
      [
        "Lyon",
        {
          id: "Lyon",
          description: "Lyon County",
        },
      ],
      [
        "Mineral",
        {
          id: "Mineral",
          description: "Mineral County",
        },
      ],
      [
        "Nye",
        {
          id: "Nye",
          description: "Nye County",
        },
      ],
      [
        "Pershing",
        {
          id: "Pershing",
          description: "Pershing County",
        },
      ],
      [
        "Storey",
        {
          id: "Storey",
          description: "Storey County",
        },
      ],
      [
        "Washoe",
        {
          id: "Washoe",
          description: "Washoe County",
        },
      ],
      [
        "White Pine",
        {
          id: "White Pine",
          description: "White Pine County",
        },
      ],
    ]),
  );
}

export type PermitStatus = "Pending" | "Issued" | "Effective" | "Expired" | "Terminated";
export function permitStatuses() {
  return createEnum(
    new Map<PermitStatus, { id: string; description: string }>([
      [
        "Pending",
        {
          id: "Pending",
          description: "Pending",
        },
      ],
      // [
      //   "Issued",
      //   {
      //     id: "Issued",
      //     description: "Issued",
      //   },
      // ],
      [
        "Effective",
        {
          id: "Effective",
          description: "Effective",
        },
      ],
      [
        "Expired",
        {
          id: "Expired",
          description: "Expired",
        },
      ],
      [
        "Terminated",
        {
          id: "Terminated",
          description: "Terminated",
        },
      ],
    ]),
  );
}

export type PermitTypes =
  | "Class 1"
  | "Class 1 and 3"
  | "Class 2"
  | "Class 2 and 3"
  | "Class 3"
  | "Class 3 Waiver"
  | "Compost"
  | "Incinerator"
  | "Materials Recovery Facility"
  | "Process Facility"
  | "Public Waste Bin"
  | "Salvage Yard"
  | "Transfer Station"
  | "Tribal Facility"
  | "Waste Tire"
  | "Written Determination";
export function permitTypes() {
  return createEnum(
    new Map<PermitTypes, { id: string; description: string; hasExpiration?: boolean; hasRenewal?: boolean }>([
      [
        "Class 1",
        {
          id: "Class 1",
          description: "Class 1",
        },
      ],
      [
        "Class 1 and 3",
        {
          id: "Class 1 and 3",
          description: "Class 1 and 3",
        },
      ],
      [
        "Class 2",
        {
          id: "Class 2",
          description: "Class 2",
        },
      ],
      [
        "Class 2 and 3",
        {
          id: "Class 2 and 3",
          description: "Class 2 and 3",
        },
      ],
      [
        "Class 3",
        {
          id: "Class 3",
          description: "Class 3",
        },
      ],
      [
        "Class 3 Waiver",
        {
          id: "Class 3 Waiver",
          description: "Class 3 Waiver",
          hasExpiration: true,
          hasRenewal: true,
        },
      ],
      [
        "Compost",
        {
          id: "Compost",
          description: "Compost",
        },
      ],
      [
        "Incinerator",
        {
          id: "Incinerator",
          description: "Incinerator",
        },
      ],
      [
        "Materials Recovery Facility",
        {
          id: "Materials Recovery Facility",
          description: "Materials Recovery Facility",
        },
      ],
      [
        "Process Facility",
        {
          id: "Process Facility",
          description: "Process Facility",
        },
      ],
      [
        "Public Waste Bin",
        {
          id: "Public Waste Bin",
          description: "Public Waste Bin",
        },
      ],
      [
        "Salvage Yard",
        {
          id: "Salvage Yard",
          description: "Salvage Yard",
        },
      ],
      [
        "Transfer Station",
        {
          id: "Transfer Station",
          description: "Transfer Station",
        },
      ],
      [
        "Tribal Facility",
        {
          id: "Tribal Facility",
          description: "Tribal Facility",
        },
      ],
      [
        "Waste Tire",
        {
          id: "Waste Tire",
          description: "Waste Tire",
        },
      ],
      [
        "Written Determination",
        {
          id: "Written Determination",
          description: "Written Determination",
        },
      ],
    ]),
  );
}

export type PermitVersionStatus = "Pending" | "Issued" | "Effective" | "Modified" | "Expired" | "Terminated";
export function permitVersionStatuses() {
  return createEnum(
    new Map<PermitVersionStatus, { id: string; description: string }>([
      [
        "Pending",
        {
          id: "Pending",
          description: "Pending",
        },
      ],
      [
        "Issued",
        {
          id: "Issued",
          description: "Issued",
        },
      ],
      [
        "Effective",
        {
          id: "Effective",
          description: "Effective",
        },
      ],
      [
        "Modified",
        {
          id: "Modified",
          description: "Modified",
        },
      ],
      [
        "Expired",
        {
          id: "Expired",
          description: "Expired",
        },
      ],
      [
        "Terminated",
        {
          id: "Terminated",
          description: "Terminated",
        },
      ],
    ]),
  );
}

export type PermitVersionTypes = "Initial" | "AdministrativeAmendment" | "Modification" | "Renewal";
export function permitVersionTypes() {
  return createEnum(
    new Map<PermitVersionTypes, { id: string; description: string }>([
      [
        "Initial",
        {
          id: "Initial",
          description: "Initial Permit",
        },
      ],
      [
        "AdministrativeAmendment",
        {
          id: "AdministrativeAmendment",
          description: "Administrative Amendment",
        },
      ],
      [
        "Modification",
        {
          id: "Modification",
          description: "Permit Modification",
        },
      ],
      [
        "Renewal",
        {
          id: "Renewal",
          description: "Permit Renewal",
        },
      ],
    ]),
  );
}

export type RecyclingCategories = "MaterialGenerator" | "StateAgency" | "WasteHaulerProcessor";
export function recyclingCategories() {
  return createEnum(
    new Map<RecyclingCategories, { id: string; description: string }>([
      [
        "MaterialGenerator",
        {
          id: "MaterialGenerator",
          description: "Material Generator",
        },
      ],
      [
        "StateAgency",
        {
          id: "StateAgency",
          description: "State Agency",
        },
      ],
      [
        "WasteHaulerProcessor",
        {
          id: "WasteHaulerProcessor",
          description: "Waste Hauler and/or Processor",
        },
      ],
    ]),
  );
}

export type RecyclingReportStatus = "Draft" | "Submitted" | "Verified";
export function recyclingReportStatuses() {
  return createEnum(
    new Map<RecyclingReportStatus, { id: string; description: string }>([
      [
        "Draft",
        {
          id: "Draft",
          description: "Draft",
        },
      ],
      [
        "Submitted",
        {
          id: "Submitted",
          description: "Submitted",
        },
      ],
      [
        "Verified",
        {
          id: "Verified",
          description: "Verified",
        },
      ],
    ]),
  );
}

export type ReportStatus = "Scheduled" | "Draft" | "Submitted" | "Verified" | "Waived";
export function reportStatuses() {
  return createEnum(
    new Map<ReportStatus, { id: string; description: string }>([
      [
        "Scheduled",
        {
          id: "Scheduled",
          description: "Scheduled",
        },
      ],
      [
        "Draft",
        {
          id: "Draft",
          description: "Draft",
        },
      ],
      [
        "Submitted",
        {
          id: "Submitted",
          description: "Submitted",
        },
      ],
      [
        "Verified",
        {
          id: "Verified",
          description: "Verified",
        },
      ],
      // [
      //   "PastDue",
      //   {
      //     id: "PastDue",
      //     description: "Past Due",
      //   },
      // ],
      [
        "Waived",
        {
          id: "Waived",
          description: "Waived",
        },
      ],
    ]),
  );
}

export type ReportTypes =
  | "AnnualReport"
  | "CapacityReport"
  | "ConstructionQualityAssuranceReport"
  | "DisposalReport"
  | "FinancialAssuranceReport"
  | "GasMonitoringReport"
  | "GroundwaterMonitoringReport"
  | "LeachateCollectionReport"
  | "MethaneMonitoringReport"
  | "RecyclingReport"
  | "SpillReport"
  | "VadoseZoneMonitoringReport"
  | "WasteCharacterizationReport";
export function reportTypes() {
  return createEnum(
    new Map<ReportTypes, { id: string; description: string; isPermitted?: boolean; frequencies?: string[] }>([
      [
        "AnnualReport",
        {
          id: "AnnualReport",
          description: "Annual Report",
          isPermitted: true,
          frequencies: ["Annual"],
        },
      ],
      [
        "CapacityReport",
        {
          id: "CapacityReport",
          description: "Capacity Report",
          isPermitted: true,
          frequencies: ["Annual"],
        },
      ],
      [
        "ConstructionQualityAssuranceReport",
        {
          id: "ConstructionQualityAssuranceReport",
          description: "Construction Quality Assurance Report",
          isPermitted: true,
          frequencies: ["AsNeeded"],
        },
      ],
      [
        "DisposalReport",
        {
          id: "DisposalReport",
          description: "Disposal Report",
          isPermitted: true,
          frequencies: ["Annual", "SemiAnnual", "Quarter"],
        },
      ],
      [
        "FinancialAssuranceReport",
        {
          id: "FinancialAssuranceReport",
          description: "Financial Assurance Report",
          isPermitted: true,
          frequencies: ["Annual"],
        },
      ],
      [
        "GasMonitoringReport",
        {
          id: "GasMonitoringReport",
          description: "Gas Monitoring Report",
          isPermitted: true,
          frequencies: ["Annual", "SemiAnnual", "Quarter"],
        },
      ],
      [
        "GroundwaterMonitoringReport",
        {
          id: "GroundwaterMonitoringReport",
          description: "Groundwater Monitoring Report",
          isPermitted: true,
          frequencies: ["Annual", "SemiAnnual", "Quarter"],
        },
      ],
      [
        "LeachateCollectionReport",
        {
          id: "LeachateCollectionReport",
          description: "Leachate Collection Report",
          isPermitted: true,
          frequencies: ["Annual"],
        },
      ],
      [
        "MethaneMonitoringReport",
        {
          id: "MethaneMonitoringReport",
          description: "Methane Monitoring Report",
          isPermitted: true,
          frequencies: ["Quarter"],
        },
      ],
      [
        "RecyclingReport",
        {
          id: "RecyclingReport",
          description: "Recycling Report",
          isPermitted: false,
        },
      ],
      [
        "SpillReport",
        {
          id: "SpillReport",
          description: "Spill Report",
          isPermitted: true,
          frequencies: ["AsNeeded"],
        },
      ],
      [
        "VadoseZoneMonitoringReport",
        {
          id: "VadoseZoneMonitoringReport",
          description: "Vadose Zone Monitoring Report",
          isPermitted: true,
          frequencies: ["SemiAnnual"],
        },
      ],
      [
        "WasteCharacterizationReport",
        {
          id: "WasteCharacterizationReport",
          description: "Waste Characterization Report",
          isPermitted: true,
          frequencies: ["AsNeeded"],
        },
      ],
    ]),
  );
}

export type ReportFrequencies = "Annual" | "SemiAnnual" | "Quarter" | "AsNeeded" | "OneTime";
export function reportFrequencies() {
  return createEnum(
    new Map<ReportFrequencies, { id: string; description: string; periods?: string[] }>([
      [
        "Annual",
        {
          id: "Annual",
          description: "Annual",
          periods: ["A"],
        },
      ],
      [
        "SemiAnnual",
        {
          id: "SemiAnnual",
          description: "Semi-Annual",
          periods: ["H1", "H2"],
        },
      ],
      [
        "Quarter",
        {
          id: "Quarter",
          description: "Quarterly",
          periods: ["Q1", "Q2", "Q3", "Q4"],
        },
      ],
      [
        "AsNeeded",
        {
          id: "AsNeeded",
          description: "As Needed",
        },
      ],
      [
        "OneTime",
        {
          id: "OneTime",
          description: "One Time",
        },
      ],
    ]),
  );
}

export type ReportPeriods = "Q1" | "Q2" | "Q3" | "Q4" | "H1" | "H2" | "A";
export function reportPeriods() {
  return createEnum(
    new Map<ReportPeriods, { id: string; description: string }>([
      [
        "Q1",
        {
          id: "Q1",
          description: "1st Quarter",
        },
      ],
      [
        "Q2",
        {
          id: "Q2",
          description: "2nd Quarter",
        },
      ],
      [
        "Q3",
        {
          id: "Q3",
          description: "3rd Quarter",
        },
      ],
      [
        "Q4",
        {
          id: "Q4",
          description: "4th Quarter",
        },
      ],
      [
        "H1",
        {
          id: "H1",
          description: "1st Half",
        },
      ],
      [
        "H2",
        {
          id: "H2",
          description: "2nd Half",
        },
      ],
      [
        "A",
        {
          id: "A",
          description: "Annual",
        },
      ],
    ]),
  );
}

//prettier-ignore
export type StateAbbreviations = "NV" | "AK" | "AL" | "AR" | "AZ" | "CA" | "CO" | "CT" | "DC" | "DE" | "FL" | "GA" | "HI" | "IA" | "ID" | "IL" | "IN" | "KS" | "KY" | "LA" | "MA" | "MD" | "ME" | "MI" | "MN" | "MO" | "MS" | "MT" | "NC" | "ND" | "NE" | "NH" | "NJ" | "NM" | "NY" | "OH" | "OK" | "OR" | "PA" | "PR" | "RI" | "SC" | "SD" | "TN" | "TX" | "UT" | "VT"  | "VA" | "VI" | "WA" | "WI" | "WV" | "WY";
export function stateAbbreviations() {
  return createEnum(
    new Map<StateAbbreviations, { id: string; description: string }>([
      ["NV", { id: "NV", description: "NV" }],
      ["AK", { id: "AK", description: "AK" }],
      ["AL", { id: "AL", description: "AL" }],
      ["AR", { id: "AR", description: "AR" }],
      ["AZ", { id: "AZ", description: "AZ" }],
      ["CA", { id: "CA", description: "CA" }],
      ["CO", { id: "CO", description: "CO" }],
      ["CT", { id: "CT", description: "CT" }],
      ["DC", { id: "DC", description: "DC" }],
      ["DE", { id: "DE", description: "DE" }],
      ["FL", { id: "FL", description: "FL" }],
      ["GA", { id: "GA", description: "GA" }],
      ["HI", { id: "HI", description: "HI" }],
      ["IA", { id: "IA", description: "IA" }],
      ["ID", { id: "ID", description: "ID" }],
      ["IL", { id: "IL", description: "IL" }],
      ["IN", { id: "IN", description: "IN" }],
      ["KS", { id: "KS", description: "KS" }],
      ["KY", { id: "KY", description: "KY" }],
      ["LA", { id: "LA", description: "LA" }],
      ["MA", { id: "MA", description: "MA" }],
      ["MD", { id: "MD", description: "MD" }],
      ["ME", { id: "ME", description: "ME" }],
      ["MI", { id: "MI", description: "MI" }],
      ["MN", { id: "MN", description: "MN" }],
      ["MO", { id: "MO", description: "MO" }],
      ["MS", { id: "MS", description: "MS" }],
      ["MT", { id: "MT", description: "MT" }],
      ["NC", { id: "NC", description: "NC" }],
      ["ND", { id: "ND", description: "ND" }],
      ["NE", { id: "NE", description: "NE" }],
      ["NH", { id: "NH", description: "NH" }],
      ["NJ", { id: "NJ", description: "NJ" }],
      ["NM", { id: "NM", description: "NM" }],
      ["NY", { id: "NY", description: "NY" }],
      ["OH", { id: "OH", description: "OH" }],
      ["OK", { id: "OK", description: "OK" }],
      ["OR", { id: "OR", description: "OR" }],
      ["PA", { id: "PA", description: "PA" }],
      ["PR", { id: "PR", description: "PR" }],
      ["RI", { id: "RI", description: "RI" }],
      ["SC", { id: "SC", description: "SC" }],
      ["SD", { id: "SD", description: "SD" }],
      ["TN", { id: "TN", description: "TN" }],
      ["TX", { id: "TX", description: "TX" }],
      ["UT", { id: "UT", description: "UT" }],
      ["VT", { id: "VT", description: "VT" }],
      ["VA", { id: "VA", description: "VA" }],
      ["VI", { id: "VI", description: "VI" }],
      ["WA", { id: "WA", description: "WA" }],
      ["WI", { id: "WI", description: "WI" }],
      ["WV", { id: "WV", description: "WV" }],
      ["WY", { id: "WY", description: "WY" }],
    ]),
  );
}

export type WasteCounties =
  | "Other - out of state"
  | "Carson City"
  | "Churchill"
  | "Clark"
  | "Douglas"
  | "Elko"
  | "Esmeralda"
  | "Eureka"
  | "Humboldt"
  | "Lander"
  | "Lincoln"
  | "Lyon"
  | "Mineral"
  | "Nye"
  | "Pershing"
  | "Storey"
  | "Washoe"
  | "White Pine"
  | "Alpine"
  | "Amador"
  | "El Dorado"
  | "Inyo"
  | "Lassen"
  | "Modoc"
  | "Mohave"
  | "Nevada"
  | "Paiute Tribe"
  | "Placer"
  | "Plumas"
  | "Sacramento"
  | "San Bernardino"
  | "Tooele";
export function wasteCounties() {
  return createEnum(
    new Map<WasteCounties, { id: string; description: string; state?: string }>([
      [
        "Other - out of state",
        {
          id: "Other - out of state",
          description: "Other - out of state",
        },
      ],
      [
        "Carson City",
        {
          id: "Carson City",
          description: "Carson City, NV",
          state: "NV",
        },
      ],
      [
        "Churchill",
        {
          id: "Churchill",
          description: "Churchill County, NV",
          state: "NV",
        },
      ],
      [
        "Clark",
        {
          id: "Clark",
          description: "Clark County, NV",
          state: "NV",
        },
      ],
      [
        "Douglas",
        {
          id: "Douglas",
          description: "Douglas County, NV",
          state: "NV",
        },
      ],
      [
        "Elko",
        {
          id: "Elko",
          description: "Elko County, NV",
          state: "NV",
        },
      ],
      [
        "Esmeralda",
        {
          id: "Esmeralda",
          description: "Esmeralda County, NV",
          state: "NV",
        },
      ],
      [
        "Eureka",
        {
          id: "Eureka",
          description: "Eureka County, NV",
          state: "NV",
        },
      ],
      [
        "Humboldt",
        {
          id: "Humboldt",
          description: "Humboldt County, NV",
          state: "NV",
        },
      ],
      [
        "Lander",
        {
          id: "Lander",
          description: "Lander County, NV",
          state: "NV",
        },
      ],
      [
        "Lincoln",
        {
          id: "Lincoln",
          description: "Lincoln County, NV",
          state: "NV",
        },
      ],
      [
        "Lyon",
        {
          id: "Lyon",
          description: "Lyon County, NV",
          state: "NV",
        },
      ],
      [
        "Mineral",
        {
          id: "Mineral",
          description: "Mineral County, NV",
          state: "NV",
        },
      ],
      [
        "Nye",
        {
          id: "Nye",
          description: "Nye County, NV",
          state: "NV",
        },
      ],
      [
        "Pershing",
        {
          id: "Pershing",
          description: "Pershing County, NV",
          state: "NV",
        },
      ],
      [
        "Storey",
        {
          id: "Storey",
          description: "Storey County, NV",
          state: "NV",
        },
      ],
      [
        "Washoe",
        {
          id: "Washoe",
          description: "Washoe County, NV",
          state: "NV",
        },
      ],
      [
        "White Pine",
        {
          id: "White Pine",
          description: "White Pine County, NV",
          state: "NV",
        },
      ],
      [
        "Alpine",
        {
          id: "Alpine",
          description: "Alpine County, CA",
          state: "CA",
        },
      ],
      [
        "Amador",
        {
          id: "Amador",
          description: "Amador County, CA",
          state: "CA",
        },
      ],
      [
        "El Dorado",
        {
          id: "El Dorado",
          description: "El Dorado County, CA",
          state: "CA",
        },
      ],
      [
        "Inyo",
        {
          id: "Inyo",
          description: "Inyo County, CA",
          state: "CA",
        },
      ],
      [
        "Lassen",
        {
          id: "Lassen",
          description: "Lassen County, CA",
          state: "CA",
        },
      ],
      [
        "Modoc",
        {
          id: "Modoc",
          description: "Modoc County, CA",
          state: "CA",
        },
      ],
      [
        "Mohave",
        {
          id: "Mohave",
          description: "Mohave County, AZ",
          state: "CA",
        },
      ],
      [
        "Nevada",
        {
          id: "Nevada",
          description: "Nevada County, CA",
          state: "CA",
        },
      ],
      [
        "Paiute Tribe",
        {
          id: "Paiute Tribe",
          description: "Paiute Tribe",
          state: "CA",
        },
      ],
      [
        "Placer",
        {
          id: "Placer",
          description: "Placer County, CA",
          state: "CA",
        },
      ],
      [
        "Plumas",
        {
          id: "Plumas",
          description: "Plumas County, CA",
          state: "CA",
        },
      ],
      [
        "Sacramento",
        {
          id: "Sacramento",
          description: "Sacramento County, CA",
          state: "CA",
        },
      ],
      [
        "San Bernardino",
        {
          id: "San Bernardino",
          description: "San Bernardino County, CA",
          state: "CA",
        },
      ],
      [
        "Tooele",
        {
          id: "Tooele",
          description: "Tooele County, UT",
          state: "UT",
        },
      ],
    ]),
  );
}
