import { RouterView } from "vue-router";
import EntityAudits from "@/components/EntityAudits.vue";
import { EntityDocuments } from "@modernary/mui/components/document";
import { EntityNotes } from "@modernary/mui/components/note";
// Troubleshoot your routes using this - https://paths.esm.dev

const names = {
  permitVersions: "PermitVersions",
  permitVersionList: "PermitVersionList",
  permitVersionNew: "PermitVersionNew",
  permitVersionBase: "PermitVersionBase",
  permitVersionView: "PermitVersionView",
  permitVersionEdit: "PermitVersionEdit",
  permitVersionIssue: "PermitVersionIssue",
  permitVersionNotes: "PermitVersionNotes",
  permitVersionNoteEdit: "PermitVersionNoteEdit",
  permitVersionNoteNew: "PermitVersionNoteNew",
  permitVersionDocuments: "PermitVersionDocuments",
  permitVersionDocumentEdit: "PermitVersionDocumentEdit",
  permitVersionDocumentNew: "PermitVersionDocumentNew",
  permitVersionMaterialsPermitted: "PermitVersionMaterialsPermitted",
  permitVersionMaterialsPermittedEdit: "PermitVersionMaterialsPermittedEdit",
  permitVersionReportSchedules: "PermitVersionReportSchedules",
  permitVersionReportScheduleNew: "PermitVersionReportScheduleNew",
  permitVersionReportScheduleImport: "PermitVersionReportScheduleImport",
  permitVersionReportScheduleEdit: "PermitVersionReportScheduleEdit",
  permitVersionAudit: "PermitVersionAudit",
};

export { names as permitVersionRouteNames };

/** @type {import('vue-router').RouterOptions['routes']} */
const permitVersionRoutes = [
  {
    path: "/permitVersions",
    name: names.permitVersions,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.permitVersionList },
    children: [
      {
        path: "list",
        name: names.permitVersionList,
        meta: { title: "Permit Versions" },
        component: () => import("@/views/permitVersion/PermitVersionList.vue"),
      },
      {
        path: "new",
        component: () => import("@/views/permitVersion/PermitVersionBase.vue"),
        redirect: { name: names.permitVersionNew },
        props: true,
        children: [
          {
            path: "",
            name: names.permitVersionNew,
            meta: { title: "New PermitVersion", isNew: true },
            component: () => import("@/views/permitVersion/PermitVersionEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.permitVersionBase,
        component: () => import("@/views/permitVersion/PermitVersionBase.vue"),
        redirect: { name: names.permitVersionView },
        children: [
          {
            path: "view",
            name: names.permitVersionView,
            meta: { title: "Permit Version View" },
            component: () => import("@/views/permitVersion/PermitVersionView.vue"),
          },
          {
            path: "edit",
            name: names.permitVersionEdit,
            meta: { title: "Permit Version Edit", isForm: true },
            component: () => import("@/views/permitVersion/PermitVersionEdit.vue"),
          },
          {
            path: "issue",
            name: names.permitVersionIssue,
            meta: { title: "Issue Permit Version", isForm: true },
            component: () => import("@/views/permitVersion/PermitVersionIssue.vue"),
          },
          {
            path: "notes",
            name: names.permitVersionNotes,
            meta: { title: "Permit Version Internal Notes" },
            component: {
              render: () => <EntityNotes idField="permitVersionId" routeNamePrefix="PermitVersionNote" />,
            },
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.permitVersionNoteEdit,
            meta: { title: "Edit Permit Version Internal Note", isForm: true },
            component: () => import("@/views/permitVersion/PermitVersionNote.vue"),
          },
          {
            path: "notes/new",
            name: names.permitVersionNoteNew,
            meta: { title: "Create Permit Version Internal Note", isForm: true },
            component: () => import("@/views/permitVersion/PermitVersionNote.vue"),
          },
          {
            path: "documents",
            name: names.permitVersionDocuments,
            meta: { title: "Permit Version Documents" },
            component: {
              render: () => <EntityDocuments idField="permitVersionId" routeNamePrefix="PermitVersionDocument" />,
            },
          },
          {
            path: "documents/:documentId(\\d+)",
            name: names.permitVersionDocumentEdit,
            meta: { title: "Edit Permit Version Document", isForm: true },
            component: () => import("@/views/permitVersion/PermitVersionDocument.vue"),
          },
          {
            path: "documents/new",
            name: names.permitVersionDocumentNew,
            meta: { title: "Create Permit Version Document", isForm: true },
            component: () => import("@/views/permitVersion/PermitVersionDocument.vue"),
          },
          {
            path: "materialsPermitted",
            name: names.permitVersionMaterialsPermitted,
            meta: { title: "Permit Version Material Permitted" },
            component: () => import("@/views/permitVersion/PermitVersionMaterialsPermitted.vue"),
          },
          {
            path: "materialsPermitted/edit/:import(import)?",
            name: names.permitVersionMaterialsPermittedEdit,
            meta: { title: "Edit Permit Version Material Permitted", isForm: true },
            component: () => import("@/views/permitVersion/PermitVersionMaterialsPermittedEdit.vue"),
          },
          {
            path: "reportSchedules",
            name: names.permitVersionReportSchedules,
            meta: { title: "Permit Version Report Schedules" },
            component: () => import("@/views/permitVersion/PermitVersionReportSchedules.vue"),
          },
          {
            path: "reportSchedules/new",
            name: names.permitVersionReportScheduleNew,
            meta: { title: "Create Permit Version Report Schedule", isForm: true },
            component: () => import("@/views/permitVersion/PermitVersionReportScheduleEdit.vue"),
          },
          {
            path: "reportSchedules/import",
            name: names.permitVersionReportScheduleImport,
            meta: { title: "Import Permit Version Report Schedules", isForm: true },
            component: () => import("@/views/permitVersion/PermitVersionReportScheduleImport.vue"),
          },
          {
            path: "reportSchedules/:reportScheduleId(\\d+)",
            name: names.permitVersionReportScheduleEdit,
            meta: { title: "Edit Permit Version Report Schedule", isForm: true },
            component: () => import("@/views/permitVersion/PermitVersionReportScheduleEdit.vue"),
          },
          {
            path: "activity",
            name: names.permitVersionAudit,
            meta: { title: "Permit Version Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="PermitVersion" />,
            },
          },
        ],
      },
    ],
  },
];
export default permitVersionRoutes;
