import { RouterView } from "vue-router";
// Troubleshoot your routes using this - https://paths.esm.dev

const names = {
  persons: "Persons",
  personList: "PersonList",
  // personNew: "PersonNew",
  // personBase: "PersonBase",
  // personView: "PersonView",
  // personEdit: "PersonEdit",
};

export { names as personRouteNames };

/** @type {import('vue-router').RouterOptions['routes']} */
const personRoutes = [
  {
    path: "/persons",
    name: names.persons,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.personList },
    children: [
      {
        path: "list",
        name: names.personList,
        meta: { title: "Contacts" },
        component: () => import("@/views/person/PersonList.vue"),
      } /*,
      {
        path: "new",
        component: () => import("@/views/person/PersonBase.vue"),
        redirect: { name: names.personNew },
        props: true,
        children: [
          {
            path: "",
            name: names.personNew,
            meta: { title: "New Contact", isNew: true },
            component: () => import("@/views/person/PersonEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.personBase,
        component: () => import("@/views/person/PersonBase.vue"),
        redirect: { name: names.personView },
        children: [
          {
            path: "view",
            name: names.personView,
            meta: { title: "Contact View" },
            component: () => import("@/views/person/PersonView.vue"),
          },
          {
            path: "edit",
            name: names.personEdit,
            meta: { title: "Contact Edit", isForm: true },
            component: () => import("@/views/person/PersonEdit.vue"),
          },
        ],
      },*/,
    ],
  },
];
export default personRoutes;
