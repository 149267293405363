import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";

import { apiService, IPaging, IPagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";

import { apiHosts } from "@/api/constants/apiHosts";
import { IInspection, IInvestigation, IInvestigationNew } from "@/views/inspection/inspection";
import type { AxiosResponse } from "axios";

const URL = "investigations";
const ENTITY_NAME = "Investigation";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<IInvestigation, IPaging<IInvestigation>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getInvestigations = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const getInvestigationById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewInvestigation = async () => {
  //optionally make any other changes as necessary before returning.
  return { data: {} } as AxiosResponse<IInvestigationNew, any>; // investigationService.getInitialData() };
};

const createInvestigation = (investigation: IInspection, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS until we have a different IInvestigation interface.
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, investigation, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateInvestigation = (investigation: IInspection, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS until we have a different IInvestigation interface.
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + investigation.id, investigation, {
    successNotifCb,
    errorNotifCb,
  });
};

const saveInvestigation = (investigation: IInspection, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS until we have a different IInvestigation interface.
  if (investigation.id) {
    return updateInvestigation(investigation, config);
  } else {
    return createInvestigation(investigation, config);
  }
};

const deleteInvestigationById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteInvestigationById,
  getInvestigations,
  getInvestigationById,
  getNewInvestigation,
  createInvestigation,
  updateInvestigation,
  saveInvestigation,
};
