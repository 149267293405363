import { RouterView } from "vue-router";
import EntityAudits from "@/components/EntityAudits.vue";
import { EntityDocuments } from "@modernary/mui/components/document";

const names = {
  inspections: "Inspections",
  inspectionList: "InspectionList",
  inspectionNew: "InspectionNew",
  inspectionBase: "InspectionBase",
  inspectionView: "InspectionView",
  inspectionEdit: "InspectionEdit",
  inspectionComplete: "InspectionComplete",
  inspectionSchedule: "InspectionSchedule",
  inspectionAffiliations: "InspectionAffiliations",
  inspectionAffiliationEdit: "InspectionAffiliationEdit",
  inspectionAffiliationNew: "InspectionAffiliationNew",
  inspectionDocuments: "InspectionDocuments",
  inspectionDocumentEdit: "InspectionDocumentEdit",
  inspectionDocumentNew: "InspectionDocumentNew",
  inspectionAudit: "InspectionAudit",
};

export { names as inspectionRouteNames };

const inspectionRoutes = [
  {
    path: "/inspections",
    name: names.inspections,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.inspectionList },
    children: [
      {
        path: "list",
        name: names.inspectionList,
        meta: { title: "Inspections" },
        component: () => import("@/views/inspection/InspectionList.vue"),
      },
      {
        path: "new/:facilitySiteId(\\d+)?",
        component: () => import("@/views/inspection/InspectionBase.vue"),
        redirect: { name: names.inspectionNew },
        props: true,
        children: [
          {
            path: "",
            name: names.inspectionNew,
            meta: { title: "New Inspection", isNew: true },
            component: () => import("@/views/inspection/InspectionEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.inspectionBase,
        component: () => import("@/views/inspection/InspectionBase.vue"),
        redirect: { name: names.inspectionView },
        children: [
          {
            path: "view",
            name: names.inspectionView,
            meta: { title: "Inspection View" },
            component: () => import("@/views/inspection/InspectionView.vue"),
          },
          {
            path: "edit",
            name: names.inspectionEdit,
            meta: { title: "Inspection Edit", isForm: true },
            component: () => import("@/views/inspection/InspectionEdit.vue"),
          },
          {
            path: "complete",
            name: names.inspectionComplete,
            meta: { title: "Complete Inspection", isForm: true },
            component: () => import("@/views/inspection/InspectionComplete.vue"),
          },
          {
            path: "schedule/:facilitySiteId(\\d+)",
            name: names.inspectionSchedule,
            meta: { title: "Schedule Next Inspection", isForm: true },
            component: () => import("@/views/inspection/InspectionSchedule.vue"),
          },
          {
            path: "affiliations",
            name: names.inspectionAffiliations,
            meta: { title: "Inspection Affiliations" },
            component: () => import("@/views/inspection/InspectionAffiliations.vue"),
          },
          {
            path: "affiliations/:affiliationId(\\d+)/:entityType(person)",
            name: names.inspectionAffiliationEdit,
            meta: { title: "Edit Inspection Site Affiliation", isForm: true },
            component: () => import("@/views/inspection/InspectionAffiliation.vue"),
          },
          {
            path: "affiliations/new",
            name: names.inspectionAffiliationNew,
            meta: { title: "Create Inspection Site Affiliation", isForm: true },
            component: () => import("@/views/inspection/InspectionAffiliation.vue"),
          },
          {
            path: "documents",
            name: names.inspectionDocuments,
            meta: { title: "Inspection Site Documents" },
            component: {
              render: () => <EntityDocuments idField="inspectionId" routeNamePrefix="InspectionDocument" />,
            },
          },
          {
            path: "documents/:documentId(\\d+)",
            name: names.inspectionDocumentEdit,
            meta: { title: "Edit Inspection Document", isForm: true },
            component: () => import("@/views/inspection/InspectionDocument.vue"),
          },
          {
            path: "documents/new",
            name: names.inspectionDocumentNew,
            meta: { title: "Create Inspection Document", isForm: true },
            component: () => import("@/views/inspection/InspectionDocument.vue"),
          },
          {
            path: "activity",
            name: names.inspectionAudit,
            meta: { title: "Inspection Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="Inspection" />,
            },
          },
          // {
          //   path: "notes",
          //   name: "InspectionNotes",
          //   meta: { title: "Inspection Site Internal Notes" },
          //   component: () => import("@/views/inspection/InspectionNotes.vue"),
          // },
          // {
          //   path: "notes/:annotationId(\\d+)",
          //   name: "InspectionNoteEdit",
          //   meta: { title: "Edit Inspection Site Internal Note", isForm: true },
          //   component: () => import("@/views/inspection/InspectionNote.vue"),
          // },
          // {
          //   path: "notes/new",
          //   name: "InspectionNoteNew",
          //   meta: { title: "Create Inspection Site Internal Note", isForm: true },
          //   component: () => import("@/views/inspection/InspectionNote.vue"),
          // },
          // {
          //   path: "disposalReports",
          //   name: "InspectionDisposalReports",
          //   meta: { title: "Inspection Site Disposal Reports" },
          //   component: () => import("@/views/inspection/DisposalReportChildList.vue"),
          // },
          // {
          //   path: "users",
          //   name: "InspectionUsers",
          //   meta: { title: "Inspection Site Users" },
          //   component: () => import("@/views/inspection/InspectionUsers.vue"),
          // },
          // {
          //   path: "users/new",
          //   name: "InspectionUserNew",
          //   meta: { title: "Add Inspection Site User", isForm: true },
          //   component: () => import("@/views/inspection/InspectionUser.vue"),
          // },
        ],
      },
    ],
  },
];
export default inspectionRoutes;
